import {configureStore} from "@reduxjs/toolkit";
import api from "../services/direct-assess-api";

const store = configureStore({
    reducer: {
        api: api.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat([
            api.middleware,
        ]),
});

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export type {
    RootState,
    AppDispatch,
}

export {
    store,
};

import React, {useCallback, useState} from "react";
import {Button, Form, FormProps, Input, Modal, Select} from "antd";
import {LockOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {IOrganisationPersonnel} from "../../services/direct-assess-api/types";
import {UpgradeModal} from "../UpgradeModal";
import {IDiscussionTopic} from "../../types/types";

const { Option } = Select;
type LayoutType = Parameters<typeof Form>[0]['layout'];

interface IProps {
    currentDiscussionTopics?: IDiscussionTopic[],
    onSubmit: (value: IDiscussionTopic[]) => void,
    personnel: IOrganisationPersonnel[],
    complete: boolean,
}

type FieldType = {
    topic?: string;
    presenters?: string[];
};

const DiscussionTopics: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const [formLayout] = useState<LayoutType>('vertical');
    const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);

    const onUpgradeClose = useCallback(() => {
        setShowUpgradeModal(false);
    }, []);

    const onUploadClick = useCallback(() => {
        setShowUpgradeModal(true);
    }, []);

    const formItemLayout = formLayout === 'horizontal' ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;
    const buttonItemLayout = formLayout === 'horizontal' ? { wrapperCol: { span: 14, offset: 4 } } : null;

    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        props.onSubmit(values as IDiscussionTopic[]);
    };

    return (
        <div className='add-page'>
            <Form
                {...formItemLayout}
                layout={formLayout}
                form={form}
                name="discussion-topics"
                className="project-form"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.List
                    name="topics"
                    rules={[
                        {
                            validator: async (_, topics) => {
                                if (!topics || topics.length < 1) {
                                    return Promise.reject(new Error('Please enter at least one topic'));
                                }
                            },
                        },
                    ]}
                >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'topic']}
                                        rules={[{ required: true, message: 'Missing topic name' }]}
                                        {...formItemLayout}
                                        label={`Discussion Topic ${key + 1}`}
                                        key={`${key}_topic`}
                                    >
                                        <Input disabled={props.complete} placeholder="Enter the discussion topic name" />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'presenters']}
                                        rules={[{ required: false, message: 'Please select a presenter or presenters', type: 'array' }]}
                                        {...formItemLayout}
                                        label="Presenter(s)"
                                        key={`${key}_presenters`}
                                    >
                                        <Select mode="multiple" placeholder="Please select presenter(s)" disabled={props.complete}>
                                            {props.personnel.map((personnel) => (
                                                    <Option key={`${key}_${personnel.hash}`} value={`${personnel.hash}`}>{`${personnel.firstName} ${personnel.lastName}`}</Option>
                                                )
                                            )}
                                        </Select>
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    icon={<PlusOutlined />}
                                    disabled={props.complete}
                                >
                                    Add topic
                                </Button>
                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item {...buttonItemLayout}>
                    <Button disabled={props.complete} type="primary" htmlType="submit">Submit</Button>
                    <Button disabled={props.complete} style={{marginLeft: '2rem'}} type="default" onClick={onUploadClick} icon={<LockOutlined />}>Upload agenda to pre-fill</Button>
                </Form.Item>
            </Form>
            <Modal
                open={showUpgradeModal}
                onCancel={onUpgradeClose}
                centered
                footer={null}
            >
                <UpgradeModal
                    onclose={onUpgradeClose}
                />
            </Modal>
        </div>
    );
}

export default DiscussionTopics;

import React, {useEffect, useMemo, useState} from "react";
import {useGetProjectLinkQuery} from "../services/direct-assess-api";

import {Grid, message, theme, Typography} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {IProjectLink} from "../services/direct-assess-api/types";
import {ProjectLinkStatusEnum} from "../enums/ProjectLinkStatusEnum";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;


const ProjectLinkPage: React.FunctionComponent = () => {
    const { token } = useToken();
    const screens = useBreakpoint();
    const [messageApi, contextHolder] = message.useMessage();
    const [title, setTitle] = useState<string>("Project Loading...");
    const [text, setText] = useState<string>("Redirecting shortly");
    const { projectHash, inviteeHash } = useParams();
    const [redirectUrl, setRedirectUrl] = useState<string>();
    const navigate = useNavigate();

    const {data, error} = useGetProjectLinkQuery({projectHash: projectHash!, inviteeHash: inviteeHash!});

    const projectLink = useMemo(() => {
        return data ? data as unknown as IProjectLink : undefined;
    }, [data]);

    useEffect(() => {
        if (projectLink) {
            switch (projectLink.status) {
                case ProjectLinkStatusEnum.Redirect:
                    setText("Redirecting now");
                    setRedirectUrl(projectLink.redirectUrl);
                    break;
                default:
                    setTitle("Oops!");

                    if (projectLink.status === ProjectLinkStatusEnum.ProjectClosed) {
                        setText("The project is now closed");
                    } else if (projectLink.status === ProjectLinkStatusEnum.InviteeComplete) {
                        setTitle("Thank you!");
                        setText("Looks like you've already completed this project. Thank you.");
                    } else {
                        setText("There was a problem with the link")
                    }
                    break;
            }
        }
    }, [projectLink]);

    useEffect(() => {
        if (redirectUrl) {
            if (redirectUrl.startsWith("http")) {
                window.location.href = redirectUrl;
            } else {
                navigate(redirectUrl, { replace: true })
            }
        }
    }, [navigate, redirectUrl]);

    const styles = {
        container: {
            margin: "0 auto",
            padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
            width: "380px"
        },
        footer: {
            marginTop: token.marginLG,
            width: "100%",
            textAlign: "center",
        },
        forgotPassword: {
        },
        header: {
            marginBottom: token.marginXL,
            textAlign: "center",
        },
        section: {
            alignItems: "center",
            backgroundColor: token.colorBgContainer,
            display: "flex",
            height: screens.sm ? "60vh" : "auto",
            padding: screens.md ? `${token.sizeXXL}px 0px` : "0px"
        },
        text: {
            color: token.colorTextSecondary
        },
        title: {
            fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
        }
    };

    return (
        <section style={styles.section}>
            {contextHolder}
            <div style={styles.container}>
                <div>
                    <div style={{
                        marginBottom: token.marginXL,
                        textAlign: "center",
                    }}>
                        <Title style={styles.title}>{title}</Title>
                        <Text style={styles.text}>
                            {text}
                        </Text>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProjectLinkPage;

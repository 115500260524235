import React from "react";
import {IFilledBoardMeetingReviewTopicResponses} from "../../services/direct-assess-api/types";
import BoardMeetingReviewProjectResponsesTopic from "./BoardMeetingReviewProjectResponsesTopic";

interface IProps {
    topicResponses: IFilledBoardMeetingReviewTopicResponses[] | undefined;
}
const BoardMeetingReviewProjectResponsesTopics: React.FunctionComponent<IProps> = (props) => {

    return (
        <div>
            <h3>Topics</h3>
            {props.topicResponses?.map((topic: IFilledBoardMeetingReviewTopicResponses, index) => {
                return (
                    <BoardMeetingReviewProjectResponsesTopic key={index} topic={topic}/>
                );
            })}
        </div>
    );
}

export default BoardMeetingReviewProjectResponsesTopics;

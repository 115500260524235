import React from "react";
import {Button} from "antd";
import {LockOutlined} from "@ant-design/icons";

interface IProps {
    onclose: () => void;
}
export const UpgradeModal: React.FunctionComponent<IProps> = (props) => {
    return (
        <>
            <h2><LockOutlined /> Upgrade to unlock</h2>
            <p>In order to unlock this feature, you need to upgrade your plan.</p>
            <Button
                type='primary'
                onClick={props.onclose}
            >
                OK
            </Button>
        </>
    );
};
import React, {useCallback, useState} from "react";
import {Button, Form, Modal} from "antd";
import {UpgradeModal} from "../UpgradeModal";
import {LockOutlined} from "@ant-design/icons";

type LayoutType = Parameters<typeof Form>[0]['layout'];

interface IProps {
    projectName?: string;
}
const Complete: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState<LayoutType>('vertical');
    const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);

    const formItemLayout = formLayout === 'horizontal' ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;
    const buttonItemLayout = formLayout === 'horizontal' ? { wrapperCol: { span: 14, offset: 4 } } : null;

    const onUpgradeClose = useCallback(() => {
        setShowUpgradeModal(false);
    }, []);

    const onSaveAsClick = useCallback(() => {
        setShowUpgradeModal(true);
    }, []);

    return (
        <div className='add-page'>
            <Form
                {...formItemLayout}
                layout={formLayout}
                form={form}
                name="project-complete"
                className="project-form"
            >
                <h2>Project '{props.projectName}' is now being built and will be available in your dashboard shortly</h2>
                <p>You can't change the content of the project now. You can always delete it and create another one.</p>
                <p>You can review the project settings using the Prev and Next buttons here.</p>
                <Form.Item {...buttonItemLayout}>
                    <Button
                        type="primary"
                        onClick={onSaveAsClick}
                        icon={<LockOutlined />}
                    >Save as template</Button>
                </Form.Item>
            </Form>
            <Modal
                open={showUpgradeModal}
                onCancel={onUpgradeClose}
                centered
                footer={null}
            >
                <UpgradeModal
                    onclose={onUpgradeClose}
                />
            </Modal>
        </div>
    );
}

export default Complete;

import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {
    EntityTag,
    IApiUser,
    IAuth,
    ICreateApiUser,
    ICreateOrganisationPersonnel,
    ICreateOrganisationWithApiUser,
    ICreateProject,
    ICreateSurveyCategoryDto, ICreateSurveySubCategoryCriteriaDto,
    ICreateSurveySubCategoryDto,
    IEditSurveyCategoryDto, IEditSurveySubCategoryCriteriaDto,
    IEditSurveySubCategoryDto,
    IOrganisation,
    IOrganisationPersonnel,
    IProject,
    IProjectLink,
    IProjectSurvey,
    IProjectSurveyResult,
    ISendInvites,
    ISurveyCategory,
    ISurveyCategoryQuestion,
    ISurveySubCategory,
    ISurveySubCategoryCriteria,
    IUpdateApiUser,
    IUpdateOrganisationPersonnel
} from "./types";
import {ProjectTypeEnum} from "../../enums/ProjectTypeEnum";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const api = createApi({
    tagTypes: [
        EntityTag.Project,
        EntityTag.Personnel,
        EntityTag.DirectorAssessmentSurveyCategory,
        EntityTag.Organisation,
    ],
    baseQuery: fetchBaseQuery({ baseUrl:  apiBaseUrl }),
    endpoints: (build) => ({
        login: build.mutation<IAuth, { email: string, password: number }>({
            query: (params) => ({
                url: `/auth/login`,
                method: 'POST',
                body: { username: params.email, password: params.password },
            }),
        }),
        resetPassword: build.mutation<void, { email: string }>({
            query: (params) => ({
                url: `/auth/reset-password`,
                method: 'PUT',
                body: { username: params.email },
            }),
        }),
        updatePassword: build.mutation<IApiUser, { token: string, password: string }>({
            query: (params) => ({
                url: `/auth/update-password`,
                method: 'PUT',
                body: { token: params.token, password: params.password },
            }),
        }),
        getProfile: build.mutation<IApiUser, { jwtToken: string }>({
            query: (params) => ({
                url: `/auth/profile`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        getProjects: build.query<{data: IProject[]}, { jwtToken: string }>({
            query: (params) => ({
                url: `/projects`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
            providesTags: (result) => [
                ...(result?.data || []).map(project => ({
                    type: EntityTag.Project,
                    id: project.id,
                })),
                {
                    type: EntityTag.Project,
                    id: 'LIST',
                },
            ],
        }),
        getProject: build.query<{data: IProject}, { jwtToken: string, id: number }>({
            query: (params) => ({
                url: `/projects/${params.id}`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        getPersonnel: build.query<{data: IOrganisationPersonnel[]}, { jwtToken: string }>({
            query: (params) => ({
                url: `/personnel`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
            providesTags: (result) => [
                ...(result?.data || []).map(personnel => ({
                    type: EntityTag.Personnel,
                    id: personnel.id,
                })),
                {
                    type: EntityTag.Personnel,
                    id: 'LIST',
                },
            ],
        }),
        getUsers: build.query<{data: IApiUser[]}, { jwtToken: string }>({
            query: (params) => ({
                url: `/users/api-users`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        updateUser: build.mutation<IApiUser, {jwtToken: string, payload: IUpdateApiUser}>({
            query: (params) => ({
                url: `/users/api-user`,
                method: 'PUT',
                body: params.payload,
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        createUser: build.mutation<IApiUser, {jwtToken: string, payload: ICreateApiUser}>({
            query: (params) => ({
                url: `/users/api-user`,
                method: 'POST',
                body: params.payload,
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        createOrganisationWithUser: build.mutation<IApiUser, {payload: ICreateOrganisationWithApiUser}>({
            query: (params) => ({
                url: `/register`,
                method: 'POST',
                body: params.payload,
            }),
        }),
        updatePersonnel: build.mutation<IOrganisationPersonnel, {jwtToken: string, payload: IUpdateOrganisationPersonnel}>({
            query: (params) => ({
                url: `/personnel`,
                method: 'PUT',
                body: params.payload,
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        createPersonnel: build.mutation<IOrganisationPersonnel, {jwtToken: string, payload: ICreateOrganisationPersonnel}>({
            query: (params) => ({
                url: `/personnel`,
                method: 'POST',
                body: params.payload,
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        createProject: build.mutation<IProject, {jwtToken: string, payload: ICreateProject}>({
            query: (params) => ({
                url: `/projects`,
                method: 'POST',
                body: params.payload,
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        getOrganisation: build.query<{data: IOrganisation}, { jwtToken: string }>({
            query: (params) => ({
                url: `/organisations`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        getAdminOrganisations: build.query<{data: IOrganisation[]}, { jwtToken: string }>({
            query: (params) => ({
                url: `/admin/organisations`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
            providesTags: (result) => [
                ...(result?.data || []).map(organisation => ({
                    type: EntityTag.Organisation,
                    id: organisation.id,
                })),
                {
                    type: EntityTag.Organisation,
                    id: 'LIST',
                },
            ],
        }),
        getAdminDirectorAssessmentSurveyCategories: build.mutation<ISurveyCategory[], { jwtToken: string, organisation: string }>({
            query: (params) => ({
                url: `/admin/categories/${ProjectTypeEnum.DirectorAssessment}/${params.organisation}`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        getAdminDirectorAssessmentSurveyCategorySubCategories: build.mutation<ISurveySubCategory[], { jwtToken: string, categoryId: number }>({
            query: (params) => ({
                url: `/admin/category-sub-categories/${ProjectTypeEnum.DirectorAssessment}/${params.categoryId}`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        getAdminDirectorAssessmentSurveySubCategoryCriteria: build.mutation<ISurveySubCategoryCriteria[], { jwtToken: string, subCategoryId: number }>({
            query: (params) => ({
                url: `/admin/sub-category-criteria/${ProjectTypeEnum.DirectorAssessment}/${params.subCategoryId}`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        createAdminDirectorAssessmentSurveyCategory: build.mutation<ISurveyCategory, {jwtToken: string, payload: ICreateSurveyCategoryDto}>({
            query: (params) => ({
                url: `/admin/categories/${ProjectTypeEnum.DirectorAssessment}`,
                method: 'POST',
                body: params.payload,
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        updateAdminDirectorAssessmentSurveyCategory: build.mutation<ISurveyCategory, {jwtToken: string, payload: IEditSurveyCategoryDto}>({
            query: (params) => ({
                url: `/admin/categories/${ProjectTypeEnum.DirectorAssessment}`,
                method: 'PUT',
                body: params.payload,
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        createAdminDirectorAssessmentSurveySubCategory: build.mutation<ISurveySubCategory, {jwtToken: string, payload: ICreateSurveySubCategoryDto}>({
            query: (params) => ({
                url: `/admin/category-sub-categories/${ProjectTypeEnum.DirectorAssessment}`,
                method: 'POST',
                body: params.payload,
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        updateAdminDirectorAssessmentSurveySubCategory: build.mutation<ISurveySubCategory, {jwtToken: string, payload: IEditSurveySubCategoryDto}>({
            query: (params) => ({
                url: `/admin/category-sub-categories/${ProjectTypeEnum.DirectorAssessment}`,
                method: 'PUT',
                body: params.payload,
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        createAdminDirectorAssessmentSurveyCriteria: build.mutation<ISurveySubCategoryCriteria, {jwtToken: string, payload: ICreateSurveySubCategoryCriteriaDto}>({
            query: (params) => ({
                url: `/admin/sub-category-criteria/${ProjectTypeEnum.DirectorAssessment}`,
                method: 'POST',
                body: params.payload,
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        updateAdminDirectorAssessmentSurveyCriteria: build.mutation<ISurveySubCategory, {jwtToken: string, payload: IEditSurveySubCategoryCriteriaDto}>({
            query: (params) => ({
                url: `/admin/sub-category-criteria/${ProjectTypeEnum.DirectorAssessment}`,
                method: 'PUT',
                body: params.payload,
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        getProjectLink: build.query<{data: IProjectLink}, { projectHash: string, inviteeHash: string }>({
            query: (params) => ({
                url: `/project-links/${params.projectHash}/${params.inviteeHash}`,
                method: 'GET',
            }),
        }),
        getProjectSurvey: build.query<{data: IProjectSurvey}, { projectHash: string, inviteeHash: string }>({
            query: (params) => ({
                url: `/project-surveys/${params.projectHash}/${params.inviteeHash}`,
                method: 'GET',
            }),
        }),
        sendProjectSurveyResult: build.mutation<IProjectSurveyResult, {projectHash: string, inviteeHash: string, payload: any}>({
            query: (params) => ({
                url: `/project-surveys/${params.projectHash}/${params.inviteeHash}`,
                method: 'POST',
                body: JSON.stringify(params.payload),
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                },
            }),
        }),
        sendProjectInvites: build.mutation<void, {jwtToken: string, payload: ISendInvites}>({
            query: (params) => ({
                url: `/projects/send-invites`,
                method: 'PUT',
                body: params.payload,
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        getDirectorAssessmentSurveyCategories: build.query<{data: ISurveyCategory[]}, { jwtToken: string, surveyType: ProjectTypeEnum.DirectorAssessment }>({
            query: (params) => ({
                url: `/survey/categories/${params.surveyType}`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
            providesTags: (result) => [
                ...(result?.data || []).map(surveyCategory => ({
                    type: EntityTag.DirectorAssessmentSurveyCategory,
                    id: surveyCategory.id,
                })),
                {
                    type: EntityTag.DirectorAssessmentSurveyCategory,
                    id: 'LIST',
                },
            ],
        }),
        getDirectorAssessmentSurveyCategoryQuestions: build.mutation<ISurveyCategoryQuestion[], { jwtToken: string, categoryIds: string }>({
            query: (params) => ({
                url: `/survey/category-questions/${params.categoryIds}`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        getDirectorAssessmentSurveyCategorySubCategories: build.mutation<ISurveySubCategory[], { jwtToken: string, categoryIds: string }>({
            query: (params) => ({
                url: `/survey/category-sub-categories/${params.categoryIds}`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
        getDirectorAssessmentSurveyCategorySubCategoryCriteria: build.mutation<ISurveySubCategoryCriteria[], { jwtToken: string, categoryIds: string }>({
            query: (params) => ({
                url: `/survey/category-sub-category-criteria/${params.categoryIds}`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${params.jwtToken}`,
                },
            }),
        }),
    }),
});

export const {
    useLoginMutation,
    useResetPasswordMutation,
    useUpdatePasswordMutation,
    useGetProfileMutation,
    useGetProjectsQuery,
    useGetProjectQuery,
    useGetPersonnelQuery,
    useUpdatePersonnelMutation,
    useCreatePersonnelMutation,
    useCreateProjectMutation,
    useGetOrganisationQuery,
    useGetUsersQuery,
    useUpdateUserMutation,
    useCreateUserMutation,
    useCreateOrganisationWithUserMutation,
    useGetProjectLinkQuery,
    useGetProjectSurveyQuery,
    useSendProjectSurveyResultMutation,
    useSendProjectInvitesMutation,
    useGetDirectorAssessmentSurveyCategoriesQuery,
    useGetDirectorAssessmentSurveyCategoryQuestionsMutation,
    useGetDirectorAssessmentSurveyCategorySubCategoryCriteriaMutation,
    useGetDirectorAssessmentSurveyCategorySubCategoriesMutation,
    useGetAdminOrganisationsQuery,
    useGetAdminDirectorAssessmentSurveyCategoriesMutation,
    useGetAdminDirectorAssessmentSurveyCategorySubCategoriesMutation,
    useGetAdminDirectorAssessmentSurveySubCategoryCriteriaMutation,
    useCreateAdminDirectorAssessmentSurveyCategoryMutation,
    useUpdateAdminDirectorAssessmentSurveyCategoryMutation,
    useCreateAdminDirectorAssessmentSurveySubCategoryMutation,
    useUpdateAdminDirectorAssessmentSurveySubCategoryMutation,
    useCreateAdminDirectorAssessmentSurveyCriteriaMutation,
    useUpdateAdminDirectorAssessmentSurveyCriteriaMutation,
} = api;

export default api;

import React from "react";

interface IProps {
}
const ProjectDefinition: React.FunctionComponent<IProps> = (props) => {

    return (
        <div className="project-tab">
            <h2>Definition coming soon...</h2>
        </div>
    );
}

export default ProjectDefinition;

import {useCallback, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";


const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = useCallback(() => {
    setVisibility(!visible);
  }, [visible]);

  const onLoginClick = useCallback(() => {
    if (visible) {
      onClose();
    }
    navigate("/sign-in", { replace: true });
  }, [navigate, onClose, visible]);

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <>
        {location.pathname !== '/sign-in' && (
          <>
            <CustomNavLinkSmall onClick={() => scrollTo("about")}>
              <Span>{t("About")}</Span>
            </CustomNavLinkSmall>
            <CustomNavLinkSmall onClick={() => scrollTo("mission")}>
              <Span>{t("Mission")}</Span>
            </CustomNavLinkSmall>
            <CustomNavLinkSmall onClick={() => scrollTo("product")}>
              <Span>{t("Product")}</Span>
            </CustomNavLinkSmall>
          </>
        )}

        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={onLoginClick}
        >
          <Span>
            <Button>{t("Sign in")}</Button>
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer onClick={() => navigate("/", { replace: true })} aria-label="homepage">
            <div>Chair<em>Tools</em></div>
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);

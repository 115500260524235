import {createContext, useCallback, useContext, useMemo} from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";

interface IAuthContext {
    user: any,
    login: (data: any) => void,
    logout: () => void,
}

const AuthContext = createContext<IAuthContext>({
    user: null,
    login: () => {},
    logout: () => {}
});

export const AuthProvider = ({ children }: any) => {
    const [user, setUser] = useLocalStorage("user", null);
    const navigate = useNavigate();

    const login = useCallback(async (data: any) => {
        setUser(data);
        navigate("/dashboard", { replace: true });
    }, [navigate, setUser]);

    const logout = useCallback(() => {
        setUser(null);
        navigate("/", { replace: true });
    }, [navigate, setUser]);

    const value = useMemo(() => ({
        user,
        login,
        logout
    }), [login, logout, user]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};

import React, {useCallback, useState} from "react";
import {Form, Select} from "antd";
import {YesNoEnum} from "../../enums/YesNoEnum";

const { Option } = Select;

type LayoutType = Parameters<typeof Form>[0]['layout'];

interface IProps {
    currentState: YesNoEnum | undefined,
    onChange: (value: YesNoEnum) => void,
    complete: boolean,
}
const BoardMeetingReviewDirectorRating: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const [formLayout] = useState<LayoutType>('vertical');

    const formItemLayout = formLayout === 'horizontal' ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;

    const onChange = useCallback((value: string) => {
        if (value) {
            props.onChange(value as YesNoEnum);
        }
    }, [props]);

    return (
        <div className='add-page'>
            <Form
                {...formItemLayout}
                layout={formLayout}
                form={form}
                name="project-board-meeting-review-director-rating"
                className="project-form"
            >
                <Form.Item name="askDirectorRating" label="Ask directors to rate other directors on Adequately Engaged / Sometimes Engaged / Disengaged / Disruptive?" rules={[{ required: true, message: 'Please select an option' }]}>
                    <Select
                        placeholder="Select an option"
                        onChange={onChange}
                        value={props.currentState ? props.currentState : null}
                        disabled={props.complete}
                    >
                        <Option key={YesNoEnum.Yes} value={YesNoEnum.Yes}>Yes</Option>
                        <Option key={YesNoEnum.No} value={YesNoEnum.No}>No</Option>
                    </Select>
                </Form.Item>
            </Form>
        </div>
    );
}

export default BoardMeetingReviewDirectorRating;

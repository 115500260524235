import React, {useEffect, useMemo, useState} from "react";
import {
    IApiUser,
    IUpdateApiUser
} from "../../services/direct-assess-api/types";
import {Button, Form, FormProps, Input, message, Select} from "antd";
import {Utils} from "../../Utils/Utils";
import {GeneralStatusEnum} from "../../enums/GeneralStatusEnum";
import {useUpdateUserMutation} from "../../services/direct-assess-api";
import {useAuth} from "../../hooks/useAuth";
import {UserRoleEnum} from "../../enums/UserRoleEnum";

const { Option } = Select;
type LayoutType = Parameters<typeof Form>[0]['layout'];

interface IProps {
    apiUser?: IApiUser;
    onClose: () => void;
}

type FieldType = {
    email: string;
    roles: string[];
    status: string;
};

const OrganisationUserUpdate: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState<LayoutType>('vertical');
    const [apiUpdateUser, apiUpdateUserResult] = useUpdateUserMutation();
    const { user, logout } = useAuth();

    const formItemLayout = formLayout === 'horizontal' ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;
    const buttonItemLayout = formLayout === 'horizontal' ? { wrapperCol: { span: 14, offset: 4 } } : null;

    const onFinish: FormProps<FieldType>['onFinish'] = async(values) => {
        const payload = {
            id: props.apiUser?.id,
            email: values.email,
            roles: JSON.stringify(values.roles),
            status: values.status,
        } as IUpdateApiUser;

        const updateResponse = await apiUpdateUser({jwtToken: user.jwtToken, payload: payload});

        if ('data' in updateResponse) {
            message.success(`'${payload.email}' updated`);
            props.onClose();
        } else {
            message.error(`Uh oh! Looks like the update of '${payload.email}' failed :(`)
        }
    };

    const roleKeys = useMemo(() => {
        return Object.values(UserRoleEnum);
    }, []);

    const statusKeys = useMemo(() => {
        return Object.values(GeneralStatusEnum);
    }, []);

    useEffect(() => {
        form.setFieldValue("email", props.apiUser?.email);
        form.setFieldValue("status", props.apiUser?.status);
        const rolesHeld = JSON.parse(props.apiUser?.roles!);
        form.setFieldValue("roles", rolesHeld);
    }, [props.apiUser]);

    return (
        <>
            <h2>Update {props.apiUser?.email}'s details</h2>
            <Form
                {...formItemLayout}
                layout={formLayout}
                form={form}
                name="user-update-form"
                className="user-form"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item<FieldType> label="Email" name="email" rules={[{ required: true, type: 'email', message: 'Please enter a valid email address'}]}>
                    <Input placeholder="Email" />
                </Form.Item>
                <Form.Item<FieldType> label="Roles" name="roles" rules={[{ required: true, message: 'At least one role be added', type: 'array'}]}>
                    <Select mode="multiple" placeholder="Select roles">
                        {roleKeys.map((role, index) => (
                                <Option key={index} value={role}>{`${Utils.readableUserRole(role as UserRoleEnum)}`}</Option>
                            )
                        )}
                    </Select>
                </Form.Item>
                <Form.Item<FieldType> label="Status" name="status" rules={[{ required: true, message: 'You have to specify a status', type: 'string'}]}>
                    <Select placeholder="Select status">
                        {statusKeys.map((status, index) => (
                                <Option key={index} value={status}>{`${Utils.readableGeneralStatus(status as GeneralStatusEnum)}`}</Option>
                            )
                        )}
                    </Select>
                </Form.Item>
                <Form.Item {...buttonItemLayout}>
                    <Button disabled={apiUpdateUserResult.isLoading} type="primary" htmlType="submit">Submit</Button>
                    <Button disabled={apiUpdateUserResult.isLoading} style={{marginLeft: '1rem'}} type="default" onClick={props.onClose} >Cancel</Button>
                </Form.Item>
            </Form>
        </>
    );
}

export default OrganisationUserUpdate;

import React, {useCallback, useState} from "react";
import {Card, Col, Row} from "antd";
import {
    IFilledDirectorAssessmentCategoryScoreDto
} from "../../../services/direct-assess-api/types";
import SubCategoryScores from "./SubCategoryScores";
import {Utils} from "../../../Utils/Utils";

interface IProps {
    categoryScores: IFilledDirectorAssessmentCategoryScoreDto[];
}

const CategoryScores: React.FunctionComponent<IProps> = (props) => {
    const [selectedCategory, setSelectedCategory] = useState<IFilledDirectorAssessmentCategoryScoreDto>();

    const onCategoryCardClick = useCallback((category: IFilledDirectorAssessmentCategoryScoreDto) => {
        if (selectedCategory && category.categoryHash === selectedCategory.categoryHash) {
            setSelectedCategory(undefined);
        } else {
            setSelectedCategory(category);
        }
    }, [selectedCategory]);

    return (
      <div className='category-scores'>
          <Row gutter={16}>
              <Col span={4}></Col>
              { props.categoryScores.map((categoryScore, index) => {
                  return (
                      <>
                          <Col key={`category_${index}`} span={4}>
                              <Card className={(selectedCategory && selectedCategory.categoryHash === categoryScore.categoryHash) ? 'selected' : ''} title={<div className='header'>{categoryScore.categoryName}</div>} bordered={true} onClick={() => onCategoryCardClick(categoryScore)}>
                                  <h2>{Utils.limitAndRound((categoryScore.categoryScore / 5) * 100, 0, 100, 1)}%</h2>
                                  <p>Click to explore</p>
                              </Card>
                          </Col>
                      </>
                  );
              })
              }
          </Row>
          {selectedCategory && (
              <SubCategoryScores subCategoryScores={selectedCategory.subCategoryScores} />
          )}
      </div>
    );
}

export default CategoryScores;

import React, {useState} from "react";
import {
    IEditSurveySubCategoryDto,
    ISurveyCategory,
    ISurveySubCategory,
} from "../../services/direct-assess-api/types";
import {Button, Form, FormProps, Input, message, Select} from "antd";
import {GeneralStatusEnum} from "../../enums/GeneralStatusEnum";
import {
    useUpdateAdminDirectorAssessmentSurveySubCategoryMutation,
} from "../../services/direct-assess-api";
import {useAuth} from "../../hooks/useAuth";

type LayoutType = Parameters<typeof Form>[0]['layout'];

const { TextArea } = Input;

interface IProps {
    onClose: () => void;
    organisation: string;
    category: ISurveyCategory;
    subCategory: ISurveySubCategory;
}

type FieldType = {
    id: number;
    position: number;
    hash: string;
    name: string;
    description: string;
    status: string;
};

const DirectorAssessmentSubCategoryEdit: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState<LayoutType>('vertical');
    const [apiUpdateDirectorAssessmentSubCategory, apiUpdateDirectorAssessmentSubCategoryResult] = useUpdateAdminDirectorAssessmentSurveySubCategoryMutation();
    const { user, logout } = useAuth();

    const formItemLayout = formLayout === 'horizontal' ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;
    const buttonItemLayout = formLayout === 'horizontal' ? { wrapperCol: { span: 14, offset: 4 } } : null;

    const onFinish: FormProps<FieldType>['onFinish'] = async(values) => {
        const payload = {
            id: values.id,
            name: values.name,
            description: values.description,
            position: values.position,
            organisationId: props.organisation === 'foundation' ? undefined : +props.organisation,
            status: values.status,
            categoryId: props.category.id,
        } as IEditSurveySubCategoryDto;

        const updateResponse = await apiUpdateDirectorAssessmentSubCategory({jwtToken: user.jwtToken, payload: payload});

        if ('data' in updateResponse) {
            message.success(`Sub category '${payload.name}' updated`);
            props.onClose();
        } else {
            message.error(`Uh oh! Looks like updating sub category '${payload.name}' failed :(`)
        }
    };

    const stateOptions = [
        {
            value: GeneralStatusEnum.Active,
        },
        {
            value: GeneralStatusEnum.Suspended,
        },
        {
            value: GeneralStatusEnum.Deleted,
        }
    ]

    return (
        <>
            <h2>Edit '{props.category.name}' category</h2>
            <Form
                {...formItemLayout}
                layout={formLayout}
                form={form}
                name="director-assessment-sub-category-edit-form"
                className="add-category-form"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item<FieldType> label="Id" name="id" rules={[{ required: true, message: 'Please enter name'}]} initialValue={props.subCategory.id}>
                    <Input placeholder="Id" disabled={true} />
                </Form.Item>
                <Form.Item<FieldType> label="Position" name="position" rules={[{ required: true, message: 'Please enter name'}]} initialValue={props.subCategory.position}>
                    <Input placeholder="Position" disabled={true} />
                </Form.Item>
                <Form.Item<FieldType> label="Hash" name="hash" rules={[{ required: true, message: 'Please enter name'}]} initialValue={props.subCategory.hash}>
                    <Input placeholder="Hash" disabled={true} />
                </Form.Item>
                <Form.Item<FieldType> label="Name" name="name" rules={[{ required: true, message: 'Please enter name'}]}  initialValue={props.subCategory.name}>
                    <Input placeholder="Category name" />
                </Form.Item>
                <Form.Item<FieldType> label="Description" name="description" rules={[{ required: false }]} initialValue={props.subCategory.description}>
                    <TextArea placeholder="Description (optional)" rows={4} />
                </Form.Item>
                <Form.Item<FieldType> label="Status" name="status" rules={[{ required: false }]} initialValue={props.subCategory.status}>
                    <Select options={stateOptions} />
                </Form.Item>
                <Form.Item {...buttonItemLayout}>
                    <Button disabled={apiUpdateDirectorAssessmentSubCategoryResult.isLoading} type="primary" htmlType="submit">Submit</Button>
                    <Button disabled={apiUpdateDirectorAssessmentSubCategoryResult.isLoading} style={{marginLeft: '1rem'}} type="default" onClick={props.onClose} >Cancel</Button>
                </Form.Item>
            </Form>
        </>
    );
}

export default DirectorAssessmentSubCategoryEdit;

import React, {useMemo, useState} from "react";
import {
    ICreateApiUser,
} from "../../services/direct-assess-api/types";
import {Button, Form, FormProps, Input, message, Select} from "antd";
import {Utils} from "../../Utils/Utils";
import {GeneralStatusEnum} from "../../enums/GeneralStatusEnum";
import {useCreateUserMutation} from "../../services/direct-assess-api";
import {useAuth} from "../../hooks/useAuth";
import {UserRoleEnum} from "../../enums/UserRoleEnum";

const { Option } = Select;
type LayoutType = Parameters<typeof Form>[0]['layout'];

interface IProps {
    onClose: () => void;
}

type FieldType = {
    email: string;
    roles: string[];
};

const OrganisationUserCreate: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState<LayoutType>('vertical');
    const [apiCreateApiUser, apiCreateApiUserResult] = useCreateUserMutation();
    const { user, logout } = useAuth();

    const formItemLayout = formLayout === 'horizontal' ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;
    const buttonItemLayout = formLayout === 'horizontal' ? { wrapperCol: { span: 14, offset: 4 } } : null;

    const onFinish: FormProps<FieldType>['onFinish'] = async(values) => {
        const payload = {
            email: values.email,
            roles: JSON.stringify(values.roles),
        } as ICreateApiUser;

        const createResponse = await apiCreateApiUser({jwtToken: user.jwtToken, payload: payload});

        if ('data' in createResponse) {
            message.success(`'${payload.email}' added`);
            props.onClose();
        } else {
            message.error(`Uh oh! Looks like adding '${payload.email}' failed :(`)
        }
    };

    const roleKeys = useMemo(() => {
        return Object.values(UserRoleEnum);
    }, []);

    const statusKeys = useMemo(() => {
        return Object.values(GeneralStatusEnum);
    }, []);

    return (
        <>
            <h2>Add user</h2>
            <Form
                {...formItemLayout}
                layout={formLayout}
                form={form}
                name="user-create-form"
                className="user-form"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item<FieldType> label="Email" name="email" rules={[{ required: true, type: 'email', message: 'Please enter a valid email address'}]}>
                    <Input placeholder="Email" />
                </Form.Item>
                <Form.Item<FieldType> label="Roles" name="roles" rules={[{ required: true, message: 'At least one role should be added', type: 'array'}]}>
                    <Select mode="multiple" placeholder="Roles">
                        {roleKeys.map((role, index) => (
                                <Option key={index} value={role}>{`${Utils.readableUserRole(role as UserRoleEnum)}`}</Option>
                            )
                        )}
                    </Select>
                </Form.Item>
                <Form.Item {...buttonItemLayout}>
                    <Button disabled={apiCreateApiUserResult.isLoading} type="primary" htmlType="submit">Submit</Button>
                    <Button disabled={apiCreateApiUserResult.isLoading} style={{marginLeft: '1rem'}} type="default" onClick={props.onClose} >Cancel</Button>
                </Form.Item>
            </Form>
        </>
    );
}

export default OrganisationUserCreate;

import React from "react";

interface IProps {
}

const DummyDirectorAssessmentProjectResponses: React.FunctionComponent<IProps> = (props) => {

    return (
      <></>
    );
}

export default DummyDirectorAssessmentProjectResponses;

import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    IEditSurveySubCategoryDto,
    ISurveyCategory,
    ISurveySubCategory,
    ISurveySubCategoryCriteria
} from "../../services/direct-assess-api/types";
import {List, Col, Row, Button, Modal} from "antd";
import {DownOutlined, SettingOutlined, UpOutlined} from "@ant-design/icons";
import DirectorAssessmentSubCategoryCreate from "./DirectorAssessmentSubCategoryCreate";
import DirectorAssessmentSubCategoryEdit from "./DirectorAssessmentSubCategoryEdit";
import {useAuth} from "../../hooks/useAuth";
import {
    useGetAdminDirectorAssessmentSurveySubCategoryCriteriaMutation,
    useUpdateAdminDirectorAssessmentSurveySubCategoryMutation
} from "../../services/direct-assess-api";
import DirectorAssessmentCriteriaList from "./DirectorAssessmentCriteriaList";

interface IProps {
    organisation: string;
    category: ISurveyCategory | undefined;
    subCategories: ISurveySubCategory[];
    onRefresh: () => void;
}

const DirectorAssessmentSubCategoryList: React.FunctionComponent<IProps> = (props) => {
    const { user, logout } = useAuth();
    const [addSubCategoryModalOpen, setAddSubCategoryModalOpen] = useState<boolean>(false);
    const [editSubCategoryModalOpen, setEditSubCategoryModalOpen] = useState<boolean>(false);
    const [selectedSubCategory, setSelectedSubCategory] = useState<ISurveySubCategory>();
    const [criteria, setCriteria] = useState<ISurveySubCategoryCriteria[]>();
    const [apiGetAdminDirectorAssessmentCategorySubCategoryCriteria, apiGetAdminDirectorAssessmentCategorySubCategoryCriteriaResult] = useGetAdminDirectorAssessmentSurveySubCategoryCriteriaMutation();
    const componentEndRef = useRef<null | HTMLDivElement>(null)
    const [apiUpdateDirectorAssessmentSubCategory, apiUpdateDirectorAssessmentSubCategoryResult] = useUpdateAdminDirectorAssessmentSurveySubCategoryMutation();

    const scrollToBottom = () => {
        componentEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [criteria]);

    const header = useMemo(() => {
        return (
            <Row>
                <Col span={2}>Pos</Col>
                <Col span={16}>Name</Col>
                <Col span={4}>Status</Col>
                <Col span={2}>Actions</Col>
            </Row>
        );
    }, []);

    const sendUpdates = useCallback(async (subCategories: ISurveySubCategory[]) => {
        // @ts-ignore
        for (const [index, subCategory] of subCategories.entries()) {
            const payload = {
                id: subCategory.id,
                name: subCategory.name,
                description: subCategory.description,
                position: index + 1,
                organisationId: subCategory.organisation ? subCategory.organisation.id : undefined,
                status: subCategory.status,
                categoryId: props.category!.id,
            } as IEditSurveySubCategoryDto;

            await apiUpdateDirectorAssessmentSubCategory({jwtToken: user.jwtToken, payload: payload});
        }
    }, [apiUpdateDirectorAssessmentSubCategory, props.category, user.jwtToken]);

    const moveSubCategoryUp = useCallback(async (subCategory: ISurveySubCategory) => {
        setSelectedSubCategory(subCategory);

        let indexOfSubCategory: number | undefined = undefined;

        const subCategories = [...props.subCategories];

        subCategories.forEach((item, index) => {
            if (item.id === subCategory.id) {
                indexOfSubCategory = index;
            }
        });

        if (indexOfSubCategory) {
            subCategories.splice(indexOfSubCategory - 1, 0, subCategories.splice(indexOfSubCategory, 1)[0]);
        }

        await sendUpdates(subCategories);

        props.onRefresh();
    }, [props, sendUpdates]);

    const moveSubCategoryDown = useCallback(async (subCategory: ISurveySubCategory) => {
        setSelectedSubCategory(subCategory);

        let indexOfSubCategory: number | undefined = undefined;

        const subCategories = [...props.subCategories];

        subCategories.forEach((item, index) => {
            if (item.id === subCategory.id) {
                indexOfSubCategory = index;
            }
        });

        if (indexOfSubCategory) {
            subCategories.splice(indexOfSubCategory + 1, 0, subCategories.splice(indexOfSubCategory, 1)[0]);
        }

        await sendUpdates(subCategories);

        props.onRefresh();
    }, [props, sendUpdates]);

    const editSubCategory = useCallback((subCategory: ISurveySubCategory) => {
        setSelectedSubCategory(subCategory);
        setEditSubCategoryModalOpen(true);
    }, []);

    const subCategoryActions = useCallback((subCategory: ISurveySubCategory) => {
        return (
          <div>
              <Row>
                  <Col span={8}>{subCategory.position !== 1 && (<UpOutlined title='Move up' style={{cursor: 'pointer'}} onClick={() => moveSubCategoryUp(subCategory)} />)}</Col>
                  <Col span={8}>{subCategory.position !== props.subCategories.length && (<DownOutlined title='Move down' style={{cursor: 'pointer'}} onClick={() => moveSubCategoryDown(subCategory)} />)}</Col>
                  <Col span={8}><SettingOutlined title='Edit' style={{cursor: 'pointer'}} onClick={() => editSubCategory(subCategory)} /></Col>
              </Row>
          </div>
        );
    }, [editSubCategory, moveSubCategoryDown, moveSubCategoryUp, props.subCategories.length]);

    const loadCriteria = useCallback(async (subCategory: ISurveySubCategory) => {
        const getAdminDirectorAssessmentCategorySubCategoryCriteriaResponse = await apiGetAdminDirectorAssessmentCategorySubCategoryCriteria({
            jwtToken: user.jwtToken,
            subCategoryId: subCategory.id
        });

        if ('data' in getAdminDirectorAssessmentCategorySubCategoryCriteriaResponse) {
            setCriteria(getAdminDirectorAssessmentCategorySubCategoryCriteriaResponse.data);
        }
    }, [apiGetAdminDirectorAssessmentCategorySubCategoryCriteria, user.jwtToken]);

    const onSubCategoryClicked = useCallback(async (subCategory: ISurveySubCategory) => {
        if (selectedSubCategory && subCategory.id === selectedSubCategory.id) {
            setSelectedSubCategory(undefined);
            setCriteria(undefined);
        } else {
            setSelectedSubCategory(subCategory);
            await loadCriteria(subCategory);
        }
    }, [loadCriteria, selectedSubCategory]);

    const addSubCategory = useCallback(() => {
        setAddSubCategoryModalOpen(true);
    }, []);

    const onCreateSubCategoryClose = useCallback(() => {
        setAddSubCategoryModalOpen(false);
        props.onRefresh();
    }, [props]);

    const onEditSubCategoryClose = useCallback(() => {
        setEditSubCategoryModalOpen(false);
        props.onRefresh();
    }, [props]);

    const nextSubCategoryPosition = useMemo(() => {
        let maxPosition = 0;

        if (props.subCategories) {

            props.subCategories.forEach((subCategory) => {
                if (subCategory.position > maxPosition) {
                    maxPosition = subCategory.position;
                }
            });
        }

        return maxPosition + 1;
    }, [props.subCategories]);

    return (
        <>
            <div className='director-assessment-category-list'>
                <h2><em>{props.category?.name}</em> sub categories</h2>

                <List
                    header={header}
                    bordered
                    dataSource={props.subCategories}
                    renderItem={(item) => (
                        <List.Item
                            style={{backgroundColor: selectedSubCategory?.id === item.id ? 'lightgrey' : 'transparent'}}>
                            <Row style={{width: '100%'}}>
                                <Col style={{cursor: 'pointer'}} onClick={() => onSubCategoryClicked(item)}
                                     span={2}>{item.position}</Col>
                                <Col style={{cursor: 'pointer'}} onClick={() => onSubCategoryClicked(item)}
                                     span={16}>{item.name}</Col>
                                <Col span={4}>{item.status}</Col>
                                <Col span={2}>{subCategoryActions(item)}</Col>
                            </Row>
                        </List.Item>
                    )}
                />
                <Button type='primary' className='add-category-button' onClick={addSubCategory}>
                    Add Sub Category
                </Button>
                {criteria && (
                    <DirectorAssessmentCriteriaList criteria={criteria} subCategory={selectedSubCategory}
                                                    organisation={props.organisation}
                                                    onRefresh={() => loadCriteria(selectedSubCategory!)}/>
                )}
                <Modal
                    open={addSubCategoryModalOpen}
                    onCancel={onCreateSubCategoryClose}
                    centered
                    footer={null}
                    destroyOnClose={true}
                >
                    <DirectorAssessmentSubCategoryCreate category={props.category!} onClose={onCreateSubCategoryClose}
                                                         position={nextSubCategoryPosition}
                                                         organisation={props.organisation}/>
                </Modal>
                <Modal
                    open={editSubCategoryModalOpen}
                    onCancel={onEditSubCategoryClose}
                    centered
                    footer={null}
                    destroyOnClose={true}
                >
                    <DirectorAssessmentSubCategoryEdit onClose={onEditSubCategoryClose} subCategory={selectedSubCategory!}
                                                       organisation={props.organisation} category={props.category!!}/>
                </Modal>
            </div>
            <div ref={componentEndRef} />
        </>
    );
}

export default DirectorAssessmentSubCategoryList;

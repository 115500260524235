import React, {useCallback, useState} from "react";
import {Button, Form, Grid, Input, message, theme, Typography} from "antd";
import {BankOutlined, ExclamationCircleOutlined, MailOutlined, UserOutlined} from "@ant-design/icons";
import {useCreateOrganisationWithUserMutation} from "../services/direct-assess-api";
import {ICreateOrganisationWithApiUser} from "../services/direct-assess-api/types";

const { useToken } = theme;
const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;

const SignupPage: React.FunctionComponent = () => {
    const { token } = useToken();
    const screens = useBreakpoint();
    const [messageApi, contextHolder] = message.useMessage();
    const [hideForm, setHideForm] = useState<boolean>(false);
    const [register, registerResult] = useCreateOrganisationWithUserMutation();

    const onRegisterError = useCallback(() => {
        messageApi.open({
            type: 'error',
            content: 'Oops, looks like there was a problem registering, please contact us for further assistance.',
            className: 'custom-class',
            style: {
                marginTop: '5vh',
                fontSize: '1.2rem'
            },
            duration: 6,
            icon: <ExclamationCircleOutlined style={{fontSize: '1.2rem', paddingBottom: '0.1rem'}} />
        });
    }, [messageApi]);

    const handleRegister = useCallback(async (values: any) => {
        const payload = {
            organisationName: values.organisationName,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
        } as ICreateOrganisationWithApiUser;

        const registerResponse = await register({payload: payload});

        if ('data' in registerResponse) {
            setHideForm(true);
        } else {
            onRegisterError();
        }
    }, [onRegisterError, register]);

    const styles = {
        container: {
            margin: "0 auto",
            padding: screens.md ? `${token.paddingXL}px` : `${token.sizeXXL}px ${token.padding}px`,
            width: "380px"
        },
        footer: {
            marginTop: token.marginLG,
            width: "100%",
            textAlign: "center",
        },
        forgotPassword: {
        },
        header: {
            marginBottom: token.marginXL,
            textAlign: "center",
        },
        section: {
            alignItems: "center",
            backgroundColor: token.colorBgContainer,
            display: "flex",
            height: screens.sm ? "60vh" : "auto",
            padding: screens.md ? `${token.sizeXXL}px 0px` : "0px"
        },
        text: {
            color: token.colorTextSecondary
        },
        title: {
            fontSize: screens.md ? token.fontSizeHeading2 : token.fontSizeHeading3
        }
    };

    return (
        <section style={styles.section}>
            {contextHolder}
            <div style={styles.container}>
                <div style={{display: hideForm ? 'none' : 'block'}}>
                    <div style={{
                        marginBottom: token.marginXL,
                        textAlign: "center",
                    }}>
                        <Title style={styles.title}>Sign up</Title>
                        <Text style={styles.text}>
                            You can start monitoring the effectiveness of your board now, for FREE!
                        </Text>
                    </div>
                    <Form
                        name="normal_register"
                        onFinish={handleRegister}
                        layout="vertical"
                        requiredMark="optional"
                    >
                        <Form.Item
                            name="firstName"
                            rules={[
                                {
                                    type: "string",
                                    required: true,
                                    message: "Please enter your first name",
                                },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined/>}
                                placeholder="First name"
                            />
                        </Form.Item>
                        <Form.Item
                            name="lastName"
                            rules={[
                                {
                                    type: "string",
                                    required: true,
                                    message: "Please enter your last name",
                                },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined/>}
                                placeholder="Last name"
                            />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    type: "email",
                                    required: true,
                                    message: "Please enter a valid email address",
                                },
                            ]}
                        >
                            <Input
                                prefix={<MailOutlined/>}
                                placeholder="Work email"
                            />
                        </Form.Item>
                        <Form.Item
                            name="organisationName"
                            rules={[
                                {
                                    type: "string",
                                    required: true,
                                    message: "Please enter your organisation name",
                                },
                            ]}
                        >
                            <Input
                                prefix={<BankOutlined/>}
                                placeholder="Organisation name"
                            />
                        </Form.Item> <Form.Item style={{marginBottom: "0px"}}>
                        <Button block={true} type="primary" htmlType="submit"
                                disabled={registerResult.isLoading}>
                            Sign up
                        </Button>
                        <div style={{
                            marginTop: token.marginLG,
                            width: "100%",
                            textAlign: "center",
                        }}>
                            <Text style={styles.text}>Already have an account?</Text>{" "}
                            <Link href="/sign-in">Sign in here</Link>
                        </div>
                    </Form.Item>
                    </Form>
                </div>
                <div style={{display: hideForm ? 'block' : 'none'}}>
                    <div style={{
                        marginBottom: token.marginXL,
                        textAlign: "center",
                    }}>
                        <Title style={styles.title}>Thank you for signing up!</Title>
                        <Text style={styles.text}>
                            We've sent an email to you, with instructions on how to complete the setup your account.
                        </Text>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SignupPage;

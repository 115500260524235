import React from "react";
import {IFilledResponses} from "../../services/direct-assess-api/types";
import BoardMeetingReviewProjectResponsesTopics from "./BoardMeetingReviewProjectResponsesTopics";
import {Utils} from "../../Utils/Utils";

interface IProps {
    filledResponses: IFilledResponses | undefined;
}
const BoardMeetingReviewProjectResponses: React.FunctionComponent<IProps> = (props) => {

    return (
        <>
            <h3>Number of responses received: {props.filledResponses?.numberResponsesReceived} out of {props.filledResponses?.numberResponsesExpected} ({Utils.percentageFormatter((props.filledResponses?.numberResponsesReceived! / props.filledResponses?.numberResponsesExpected!) * 100)})</h3>
            <BoardMeetingReviewProjectResponsesTopics topicResponses={props.filledResponses?.filledBoardMeetingReviewTopicResponses} />
        </>
    );
}

export default BoardMeetingReviewProjectResponses;

import React, {useMemo, useState} from "react";
import {GetProp, Select} from 'antd';
import {Button, Checkbox, Form, FormProps} from "antd";
import {IOrganisationPersonnel} from "../../services/direct-assess-api/types";

const { Option } = Select;
type LayoutType = Parameters<typeof Form>[0]['layout'];
type CheckboxValueType = GetProp<typeof Checkbox.Group, 'value'>[number];

interface IProps {
    currentState: any,
    onSubmit: (directors: any) => void,
    complete: boolean,
    personnel: IOrganisationPersonnel[],
}

type FieldType = {
    directors?: string[];
};

const DirectorAssessmentDirectors: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const [formLayout] = useState<LayoutType>('vertical');
    const defaultCheckedList = useMemo(() => {
        return props.personnel.map((personnel) => personnel.hash);
    }, [props]);
    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(props.currentState ? props.currentState.questions : defaultCheckedList);

    const onChange = (list: CheckboxValueType[]) => {
        setCheckedList(list);
    };

    const formItemLayout = formLayout === 'horizontal' ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;
    const buttonItemLayout = formLayout === 'horizontal' ? { wrapperCol: { span: 14, offset: 4 } } : null;

    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        props.onSubmit(values.directors);
    };

    return (
        <div className='add-page'>
            <Form
                {...formItemLayout}
                layout={formLayout}
                form={form}
                name={`project-director-assessment-directors`}
                className="project-form"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item<FieldType> label="As part of the assessment process, respondents nominate the most competent directors for each question in each category, choose which directors to include." name="directors" rules={[{ required: true, message: 'You need to select which directors to include', type: 'array'}]}>
                    <Select mode="multiple" placeholder="Select directors to rate" disabled={props.complete}>
                        {props.personnel.map((personnel) => {
                            if (personnel.isDirector) {
                                return (
                                    <Option key={personnel.hash} value={personnel.hash}>{`${personnel.firstName} ${personnel.lastName}`}</Option>
                                );
                            }

                            return null;
                        })}
                    </Select>
                </Form.Item>
                <Form.Item {...buttonItemLayout}>
                    <Button disabled={props.complete} type="primary"  htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default DirectorAssessmentDirectors;

import React from "react";
import DirectorAssessmentCategories from "./DirectorAssessmentCategories";

interface IProps {
}

const DirectorAssessment: React.FunctionComponent<IProps> = (props) => {

    return (
        <div className="project-tab">
            <DirectorAssessmentCategories />
        </div>
    );
}

export default DirectorAssessment;

import React from "react";

interface IProps {
}

const BoardMeetingReview: React.FunctionComponent<IProps> = (props) => {

    return (
        <div className="project-tab">
            <h2>Board Meeting Review coming soon...</h2>
        </div>
    );
}

export default BoardMeetingReview;

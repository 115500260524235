import React, {useCallback, useState} from "react";
import {Button, DatePicker, Form, FormProps, Input, Modal, Select} from "antd";
import {IOrganisationPersonnel} from "../../services/direct-assess-api/types";
import {LockOutlined} from "@ant-design/icons";
import {UpgradeModal} from "../UpgradeModal";
import {useFeatureActive} from "../../hooks/useFeatureActive";
import {FeatureTypeEnum} from "../../enums/FeatureTypeEnum";
import {IBoardMeetingDetails} from "../../types/types";

const { Option } = Select;
type LayoutType = Parameters<typeof Form>[0]['layout'];

interface IProps {
    currentState?: IBoardMeetingDetails,
    onSubmit: (value: IBoardMeetingDetails) => void,
    personnel: IOrganisationPersonnel[],
    complete: boolean,
}

type FieldType = {
    datetime: string;
    location: string;
    chair: string[];
    directorsPresent: string[];
    companySecretary: string[];
    apologies: string[];
    invitees: string[];
};

const BoardMeetingDetails: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const [formLayout] = useState<LayoutType>('vertical');
    const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
    const [uploadAgendaToPrefillActive] = useFeatureActive(FeatureTypeEnum.UploadAgendaPrefill);

    const onUpgradeClose = useCallback(() => {
        setShowUpgradeModal(false);
    }, []);

    const onUploadClick = useCallback(() => {
        if (uploadAgendaToPrefillActive) {

        } else {
            setShowUpgradeModal(true);
        }
    }, [uploadAgendaToPrefillActive]);

    const formItemLayout = formLayout === 'horizontal' ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;
    const buttonItemLayout = formLayout === 'horizontal' ? { wrapperCol: { span: 14, offset: 4 } } : null;

    const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
        const result = {
            datetime: values.datetime,
            location: values.location,
            chair: values.chair,
            directorsPresent: values.directorsPresent,
            companySecretary: values.companySecretary,
            apologies: values.apologies,
            invitees: values.invitees,
        }

        props.onSubmit(result);
    };

    return (
        <div className='add-page'>
            <Form
                {...formItemLayout}
                layout={formLayout}
                form={form}
                name="board-meeting-details"
                className="project-form"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item<FieldType> label="Where was the board meeting held (we'll use this in the survey introduction)?" name="location" rules={[{ required: true, message: 'Please enter the location'}]}>
                    <Input disabled={props.complete} placeholder="Location" />
                </Form.Item>
                <Form.Item<FieldType> label="What was the date and time of the meeting?" name="datetime" rules={[{ required: true, message: 'Please enter date and time of the meeting'}]}>
                    <DatePicker
                        disabled={props.complete}
                        format="DD/MM/YYYY hh:mm A"
                        showTime={{ use12Hours: true }}
                    />
                </Form.Item>
                <Form.Item<FieldType> label="Who was the Chair?" name="chair" rules={[{ required: true, message: 'Please select who was the Chair', type: 'array'}]}>
                    <Select mode="multiple" placeholder="Please select the Chair" disabled={props.complete}>
                        {props.personnel.map((personnel) => (
                                <Option key={personnel.hash} value={personnel.hash}>{`${personnel.firstName} ${personnel.lastName}`}</Option>
                            )
                        )}
                    </Select>
                </Form.Item>
                <Form.Item<FieldType> label="Who was the Company Secretary?" name="companySecretary" rules={[{ required: true, message: 'Please select who was the Company Secretary', type: 'array'}]}>
                    <Select mode="multiple" placeholder="Please select company secretary" disabled={props.complete}>
                        {props.personnel.map((personnel) => (
                                <Option key={personnel.hash} value={personnel.hash}>{`${personnel.firstName} ${personnel.lastName}`}</Option>
                            )
                        )}
                    </Select>
                </Form.Item>
                <Form.Item<FieldType> label="Which directors were present?" name="directorsPresent" rules={[{ required: true, message: 'Please select which directors were present', type: 'array'}]}>
                    <Select mode="multiple" placeholder="Select directors that were present" disabled={props.complete}>
                        {props.personnel.map((personnel) => (
                                <Option key={personnel.hash} value={personnel.hash}>{`${personnel.firstName} ${personnel.lastName}`}</Option>
                            )
                        )}
                    </Select>
                </Form.Item>
                <Form.Item<FieldType> label="Who else was invited and present (optional)?" name="invitees" rules={[{ required: false, message: 'Please select who else was invited and present', type: 'array'}]}>
                    <Select mode="multiple" placeholder="Select who else was invited and present" disabled={props.complete}>
                        {props.personnel.map((personnel) => (
                                <Option key={personnel.hash} value={personnel.hash}>{`${personnel.firstName} ${personnel.lastName}`}</Option>
                            )
                        )}
                    </Select>
                </Form.Item>
                <Form.Item<FieldType> label="Who provided apologies (optional)?" name="apologies" rules={[{ required: false, message: 'Please select who provided apologies', type: 'array'}]}>
                    <Select mode="multiple" placeholder="Select who provided apologies" disabled={props.complete}>
                        {props.personnel.map((personnel) => (
                                <Option key={personnel.hash} value={personnel.hash}>{`${personnel.firstName} ${personnel.lastName}`}</Option>
                            )
                        )}
                    </Select>
                </Form.Item>
                <Form.Item {...buttonItemLayout}>
                    <Button disabled={props.complete} type="primary" htmlType="submit">Submit</Button>
                    <Button disabled={props.complete} style={{marginLeft: '2rem'}} type="default" onClick={onUploadClick} icon={uploadAgendaToPrefillActive ? <></> : <LockOutlined />}>Upload agenda to pre-fill</Button>
                </Form.Item>
            </Form>
            <Modal
                open={showUpgradeModal}
                onCancel={onUpgradeClose}
                centered
                footer={null}
            >
                <UpgradeModal
                    onclose={onUpgradeClose}
                />
            </Modal>
        </div>
    );
}

export default BoardMeetingDetails;

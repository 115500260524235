import React, {useCallback} from "react";
import {Card} from "antd";
import {IProject} from "../services/direct-assess-api/types";
import {useNavigate} from "react-router-dom";

interface IProps {
    project: IProject;
}
const ProjectCard: React.FunctionComponent<IProps> = (props) => {
    const navigate = useNavigate();

    const onClick = useCallback(() => {
        navigate(`/dashboard/project/${props.project.id}`, { replace: true })
    }, [navigate, props.project.id]);

    return (
        <Card onClick={onClick} className="project-card" title={props.project.name}>
            <p>{props.project.status}</p>
        </Card>
    );
}

export default ProjectCard;

import React from "react";
import {LockOutlined} from "@ant-design/icons";

interface IProps {
}
const ProjectInsights: React.FunctionComponent<IProps> = (props) => {

    return (
        <div className="project-tab">
            <h2><LockOutlined /> Upgrade to see insights</h2>
        </div>
    );
}

export default ProjectInsights;

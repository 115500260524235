import React, {useCallback, useEffect, useMemo, useState} from "react";
import {IOrganisation, IOrganisationPersonnel} from "../../services/direct-assess-api/types";
import {Button, Modal, Table} from "antd";
import {OrganisationPositionEnum} from "../../enums/OrganisationPositionEnum";
import {Utils} from "../../Utils/Utils";
import OrganisationPersonnelUpdate from "./OrganisationPersonnelUpdate";
import {useAuth} from "../../hooks/useAuth";
import {useGetOrganisationQuery} from "../../services/direct-assess-api";
import OrganisationPersonnelCreate from "./OrganisationPersonnelCreate";

interface IProps {
}

const OrganisationPersonnel: React.FunctionComponent<IProps> = (props) => {
    const { user, logout } = useAuth();
    const [personnel, setPersonnel] = useState<IOrganisationPersonnel | undefined>(undefined);
    const [createPersonnelOpen, setCreatePersonnelOpen] = useState<boolean>(false);
    const {data, refetch, error} = useGetOrganisationQuery({jwtToken: user.jwtToken}, {pollingInterval: 600000});

    useEffect(() => {
        if (error) {
            if ('status' in error) {
                if (error.status === 401) {
                    logout();
                }
            }
        }
    }, [error, logout]);

    const organisation = useMemo(() => {
        return data ? data as unknown as IOrganisation : undefined;
    }, [data]);

    const onUpdateClose = useCallback(() => {
        setPersonnel(undefined);
        refetch();
    }, [refetch]);

    const onCreateClose = useCallback(() => {
        setCreatePersonnelOpen(false);
        refetch();
    }, [refetch]);

    const onEditClicked = useCallback((id: number) => {
        setPersonnel(organisation?.personnel?.find((personnel) => personnel.id === id));
    }, [organisation]);

    const onCreateClicked = useCallback(() => {
        setCreatePersonnelOpen(true);
    }, []);

    const columns = [
        {
            title: 'Unique identifier',
            dataIndex: 'hash',
            key: 'hash',
        }, {
            title: 'First name',
            dataIndex: 'firstName',
            key: 'firstName',
        },{
            title: 'Last name',
            dataIndex: 'lastName',
            key: 'lastName',
        },{
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        }, {
            title: 'Positions',
            dataIndex: 'positions',
            key: 'positions',
            render: ((_: string, row: IOrganisationPersonnel) => {
                const positions = JSON.parse(row.positions);

                let returnValue = '';
                let first = true;
                positions.forEach((position: OrganisationPositionEnum) => {
                    if (!first) {
                        returnValue += ", ";
                    }

                    returnValue += Utils.readableOrganisationPosition(position);
                    first = false;
                });
                return returnValue;
            })
        }, {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: ((_: string, row: IOrganisationPersonnel) => {
                return Utils.readableGeneralStatus(row.status);
            })
        }, {
            title: 'Actions',
            key: 'actions',
            render: ((_: string, row: IOrganisationPersonnel) => {
                return (
                  <Button onClick={() => onEditClicked(row.id)} >
                    Edit
                  </Button>
                );
            })
        }
    ];


    return (
        <div className="project-tab">
            <Button type='primary' style={{marginBottom: '1rem'}} onClick={onCreateClicked}>
                Add personnel
            </Button>
            <Table dataSource={organisation?.personnel} columns={columns} />
            <Modal
                open={!!personnel}
                onCancel={onUpdateClose}
                centered
                footer={null}
            >
                <OrganisationPersonnelUpdate
                    personnel={personnel}
                    onClose={onUpdateClose}
                />
            </Modal>
            <Modal
                open={createPersonnelOpen}
                onCancel={onCreateClose}
                centered
                footer={null}
            >
                <OrganisationPersonnelCreate
                    onClose={onCreateClose}
                />
            </Modal>
        </div>
    );
}

export default OrganisationPersonnel;

import {Navigate, useOutlet, useNavigate, useLocation} from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import {useCallback, useMemo, useState} from "react";
import {BankOutlined, LogoutOutlined, PieChartOutlined, PlusCircleOutlined, SettingOutlined} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout, Menu, theme } from 'antd';
import {Utils} from "../Utils/Utils";

const { Header, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}

export const ProtectedLayout = () => {
    const { user, logout } = useAuth();
    const outlet = useOutlet();
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = useCallback(() => {
        logout();
    }, [logout]);

    const items: MenuItem[] = useMemo(() => {
        const returnValue = [];

        returnValue.push(getItem('Dashboard', 'dashboard', <PieChartOutlined />));
        returnValue.push(getItem('New project', 'new-project', <PlusCircleOutlined />));
        returnValue.push(getItem('Organisation', 'organisation', <BankOutlined />));
        returnValue.push(getItem('My profile', 'profile', <SettingOutlined />));

        if (Utils.userIsSuperAdmin(user)) {
            returnValue.push(getItem('Administration', 'administration', <SettingOutlined />));
        }

        returnValue.push(getItem('Sign out', 'signout', <LogoutOutlined />));

        return returnValue;
    }, [user]);

    const onClick: MenuProps['onClick'] = (e) => {
        if (e.key === "signout") {
            handleLogout();
        } else if (e.key === 'new-project') {
            navigate("/dashboard/new-project", { replace: true });
        } else if (e.key === 'profile') {
            navigate("/dashboard/profile", { replace: true });
        } else if (e.key === 'organisation') {
            navigate("/dashboard/organisation", { replace: true });
        } else if (e.key === 'administration') {
            navigate("/dashboard/administration", { replace: true });
        } else if (e.key === 'dashboard') {
            navigate("/dashboard", { replace: true });
        }
    };

    const selectedKeys = useMemo(() => {
        const returnValue = [];

        switch (location.pathname) {
            case '/dashboard/new-project':
                returnValue.push('new-project');
                break;
            case '/dashboard/profile':
                returnValue.push('profile');
                break;
            case '/dashboard/administration':
                returnValue.push('administration');
                break;
            case '/dashboard/organisation':
                returnValue.push('organisation');
                break;
            default:
                returnValue.push('dashboard');
                break;
        }

        return returnValue;
    }, [location.pathname]);

    if (!user) {
        return <Navigate to="/sign-in" />;
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <div className="demo-logo-vertical" />
                <Menu
                    onClick={onClick}
                    theme="dark"
                    selectedKeys={selectedKeys}
                    mode="inline"
                    items={items}
                />
            </Sider>
            <Layout>
                <Header style={{ padding: 0, background: colorBgContainer }} />
                {outlet}
                <Footer style={{ textAlign: 'center' }}>
                    ChairTools.com © {new Date().getFullYear()} by Syriant
                </Footer>
            </Layout>
        </Layout>
    );
};

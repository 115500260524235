import React, {createRef, useCallback, useEffect, useMemo, useState} from "react";
import {Breadcrumb, Button, Carousel, Layout, message, theme} from "antd";
import TypeSelector from "../components/AddNewProject/TypeSelector";
import BoardMeetingReviewQuestions from "../components/AddNewProject/BoardMeetingReviewQuestions";
import {CarouselRef} from "antd/es/carousel";
import {ProjectTypeEnum} from "../enums/ProjectTypeEnum";
import ProjectName from "../components/AddNewProject/ProjectName";
import DiscussionTopics from "../components/AddNewProject/DiscussionTopics";
import BoardMeetingReviewDirectorRating from "../components/AddNewProject/BoardMeetingReviewDirectorRating";
import Invitees from "../components/AddNewProject/Invitees";
import Ready from "../components/AddNewProject/Ready";
import {
    ICreateProject,
    IOrganisationPersonnel,
    ISurveyCategory,
    ISurveySubCategory,
    ISurveySubCategoryCriteria
} from "../services/direct-assess-api/types";
import {YesNoEnum} from "../enums/YesNoEnum";
import Complete from "../components/AddNewProject/Complete";
import {
    useCreateProjectMutation,
    useGetDirectorAssessmentSurveyCategoriesQuery,
    useGetDirectorAssessmentSurveyCategorySubCategoriesMutation,
    useGetDirectorAssessmentSurveyCategorySubCategoryCriteriaMutation,
    useGetPersonnelQuery,
} from "../services/direct-assess-api";
import {useAuth} from "../hooks/useAuth";
import BoardMeetingDetails from "../components/AddNewProject/BoardMeetingsDetails";
import BoardMeetingReviewExecutiveQuestions from "../components/AddNewProject/BoardMeetingReviewExecutiveQuestions";
import DirectorAssessmentCategories from "../components/AddNewProject/DirectorAssessmentCategories";
import {IBoardMeetingDetails, IBoardMeetingExecutiveQuestions, IDiscussionTopic} from "../types/types";
import DirectorAssessmentCategoryCriteria from "../components/AddNewProject/DirectorAssessmentCategoryCriteria";
import DirectorAssessmentDirectors from "../components/AddNewProject/DirectorAssessmentDirectors";

const { Content } = Layout;

const NewProjectPage: React.FunctionComponent = () => {
    const carouselRef = createRef<CarouselRef>();
    const [projectType, setProjectType] = useState<ProjectTypeEnum>();
    const [moveForward, setMoveForward] = useState<boolean>(false);
    const [slideNumber, setSlideNumber] = useState<number>(0);
    const [projectName, setProjectName] = useState<string>();
    const [discussionTopics, setDiscussionTopics] = useState<IDiscussionTopic[]>([]);
    const [boardMeetingReviewQuestions, setBoardMeetingReviewQuestions] = useState<string[]>([]);
    const [boardMeetingReviewDirectorRating, setBoardMeetingReviewDirectorRating] = useState<YesNoEnum>();
    const [invitees, setInvitees] = useState<string[]>([]);
    const [boardMeetingDetails, setBoardMeetingDetails] = useState<IBoardMeetingDetails>();
    const [boardMeetingReviewExecutiveQuestions, setBoardMeetingReviewExecutiveQuestions] = useState<IBoardMeetingExecutiveQuestions>();
    const [directorAssessmentCategories, setDirectorAssessmentCategories] = useState<string[]>([]);
    const [directorAssessmentCriteria, setDirectorAssessmentCriteria] = useState(new Map());
    const [directorAssessmentDirectors, setDirectorAssessmentDirectors] = useState<string[]>([]);
    const [directorAssessmentQuestionRating, setDirectorAssessmentQuestionRating] = useState<YesNoEnum>(YesNoEnum.No);

    const [complete, setComplete] = useState<boolean>(false);
    const { user, logout } = useAuth();
    const [apiCreateProject, apiCreateProjectResult] = useCreateProjectMutation();
    const [apiGetSubCategoryCriteria, apiGetSubCategoryCriteriaResult] = useGetDirectorAssessmentSurveyCategorySubCategoryCriteriaMutation();
    const [categorySubCategoryCriteria, setCategorySubCategoryCriteria] = useState<ISurveySubCategoryCriteria[] | undefined>(undefined);
    const [apiGetDirectorAssessmentSurveyCategorySubCategories, apiGetDirectorAssessmentSurveyCategorySubCategoriesResult] = useGetDirectorAssessmentSurveyCategorySubCategoriesMutation();
    const [categorySubCategory, setCategorySubCategory] = useState<ISurveySubCategory[] | undefined>(undefined);

    const {data, error} = useGetPersonnelQuery({jwtToken: user.jwtToken}, {pollingInterval: 600000});
    const {data : apiDirectorAssessmentCategories} = useGetDirectorAssessmentSurveyCategoriesQuery({jwtToken: user.jwtToken, surveyType: ProjectTypeEnum.DirectorAssessment});

    useEffect(() => {
        if (error) {
            if ('status' in error) {
                if (error.status === 401) {
                    logout();
                }
            }
        }
    }, [error, logout]);

    useEffect(() => {
        let active = true
        load();
        return () => { active = false }

        async function load() {
            let criteriaResult;
            let subCategoryResult;

            if (apiDirectorAssessmentCategories) {
                const categoryIds = (apiDirectorAssessmentCategories as unknown as ISurveyCategory[]).map((entry) => entry.id).join(',');

                const getDirectorAssessmentSurveyCategorySubCategoriesResponse = await apiGetDirectorAssessmentSurveyCategorySubCategories({jwtToken: user.jwtToken, categoryIds: categoryIds});
                if ('data' in getDirectorAssessmentSurveyCategorySubCategoriesResponse) {
                    subCategoryResult = getDirectorAssessmentSurveyCategorySubCategoriesResponse.data;
                }

                const getSubCategoryCriteriaResponse = await apiGetSubCategoryCriteria({jwtToken: user.jwtToken, categoryIds: categoryIds});
                if ('data' in getSubCategoryCriteriaResponse) {
                    criteriaResult = getSubCategoryCriteriaResponse.data;
                }
            }

            if (!active) {
                return
            }

            setCategorySubCategoryCriteria(criteriaResult);
            setCategorySubCategory(subCategoryResult);
        }
    }, [apiDirectorAssessmentCategories, user.jwtToken, apiGetSubCategoryCriteria, apiGetDirectorAssessmentSurveyCategorySubCategories]);

    const orderedApiDirectorAssessmentCategories = useMemo(() => {
        if (apiDirectorAssessmentCategories) {
            const apiDirectorAssessmentCategoriesToSort = [...(apiDirectorAssessmentCategories as unknown as ISurveyCategory[])];
            return apiDirectorAssessmentCategoriesToSort.sort((a, b) => a.position - b.position);
        }

        return null;
    }, [apiDirectorAssessmentCategories])

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const resetProject = useCallback(() => {
        setProjectType(undefined);
        setProjectName(undefined);
        setBoardMeetingDetails(undefined);
        setDiscussionTopics([]);
        setBoardMeetingReviewQuestions([]);
        setBoardMeetingReviewDirectorRating(undefined);
        setInvitees([]);
        setComplete(false);
        setBoardMeetingReviewExecutiveQuestions(undefined);
        setDirectorAssessmentCategories([]);
        setDirectorAssessmentCriteria(new Map());
        setDirectorAssessmentDirectors([]);
        setDirectorAssessmentQuestionRating(YesNoEnum.No);
    }, []);

    const onSlideChange = useCallback((currentSlide: number) => {
        if ((currentSlide === 0) && (slideNumber > currentSlide)) {
            carouselRef.current?.goTo(slideNumber);
        }
    }, [carouselRef, slideNumber]);

    const onProjectTypeChange = useCallback((value: ProjectTypeEnum) => {
        setProjectType(value);
        setMoveForward(true);
    }, []);

    const onProjectNameSet = useCallback((value: string) => {
        setProjectName(value);
        setMoveForward(true);
    }, []);

    const onDiscussionTopicsSet = useCallback((value: IDiscussionTopic[]) => {
        setDiscussionTopics(value);
        setMoveForward(true);
    }, []);

    const onBoardMeetingReviewQuestionsSet = useCallback((value: string[]) => {
        setBoardMeetingReviewQuestions(value);
        setMoveForward(true);
    }, []);

    const onBoardMeetingReviewDirectorRatingSet = useCallback((value: YesNoEnum) => {
        setBoardMeetingReviewDirectorRating(value);
        setMoveForward(true);
    }, []);

    const onInviteesSet = useCallback((value: any) => {
        setInvitees(value);
        setMoveForward(true);
    }, []);

    const onBoardMeetingDetailsSet = useCallback((value: IBoardMeetingDetails) => {
        setBoardMeetingDetails(value);
        setMoveForward(true);
    }, []);

    const onBoardMeetingReviewExecutiveQuestionsSet = useCallback((value: IBoardMeetingExecutiveQuestions) => {
        setBoardMeetingReviewExecutiveQuestions(value);
        setMoveForward(true);
    }, []);

    const onDirectorAssessmentCategoriesSet = useCallback((value: string[]) => {
        setDirectorAssessmentCategories(value);
        setMoveForward(true);
    }, []);

    const onDirectorAssessmentCriteriaSet = useCallback((categoryHash: string, categoryCriteria: string[]) => {
        directorAssessmentCriteria.set(categoryHash, categoryCriteria);

        setDirectorAssessmentCriteria(directorAssessmentCriteria);
        setMoveForward(true);
    }, [directorAssessmentCriteria]);

    const onDirectorAssessmentDirectorsSet = useCallback((value: any) => {
        setDirectorAssessmentDirectors(value);
        setMoveForward(true);
    }, []);

    const onDirectorAssessmentQuestionRatingSet = useCallback((value: any) => {
        setDirectorAssessmentQuestionRating(value as YesNoEnum);
        setMoveForward(true);
    }, []);

    const onBuildProject = useCallback(async() => {
        var complete = false;

        switch (projectType) {
            case ProjectTypeEnum.BoardMeetingReview:
                if (!projectName) {
                    message.error("Oops! Looks like you haven't given your project a name");
                } else if (!boardMeetingDetails) {
                    message.error("Hang on! You haven't provided the details of the board meeting yet");
                } else if (discussionTopics.length === 0) {
                    message.error("Oh! You really need to add some topics");
                } else if (boardMeetingReviewQuestions.length === 0) {
                    message.error("You need to choose which questions to ask about each topic");
                } else if (!boardMeetingReviewDirectorRating) {
                    message.error("You need to decide if you want to ask other directors additional questions");
                } else if (!boardMeetingReviewExecutiveQuestions) {
                    message.error("You need to decide if you want to ask executives additional questions");
                } else if (invitees.length === 0) {
                    message.error("Uh oh! You really need to add invitees");
                } else {
                    const payload : ICreateProject = {
                        projectType: projectType,
                        projectName: projectName,
                        boardMeetingDetails: boardMeetingDetails,
                        topics: discussionTopics,
                        boardMeetingReviewTopicQuestions: boardMeetingReviewQuestions,
                        boardMeetingReviewDirectorRatings: boardMeetingReviewDirectorRating,
                        invitees: invitees,
                        boardMeetingReviewExecutiveQuestions: boardMeetingReviewExecutiveQuestions,
                    };

                    const createProjectResponse = await apiCreateProject({jwtToken: user.jwtToken, payload: payload});

                    if ('data' in createProjectResponse) {
                        if (createProjectResponse.data.id) {
                            complete = true;
                            message.success(`Project '${createProjectResponse.data.name}' created`);
                        }
                    }
                }
                break;
            case ProjectTypeEnum.DirectorAssessment:
                let continueChecking = true;

                if (!projectName) {
                    message.error("Oops! Looks like you haven't given your project a name");
                    continueChecking = false;
                } else if (directorAssessmentCategories.length === 0) {
                    message.error("Oh! You really need to add some categories");
                    continueChecking = false;
                } else {
                    for (const categoryHash of directorAssessmentCategories) {
                        if (!directorAssessmentCriteria.has(categoryHash)) {
                            const category = orderedApiDirectorAssessmentCategories?.find((category) => category.hash === categoryHash);

                            message.error(`Hold up! Looks like you forgot to include questions in the '${category!.name}' category?`, 5);
                            continueChecking = false;
                            break;
                        }
                    }
                }

                if (continueChecking) {
                    if (directorAssessmentDirectors.length === 0) {
                        message.error("Uh oh! Looks like you forgot to indicate which directors to include");
                    } else if (!directorAssessmentQuestionRating) {
                        message.error("You need to decide if you want respondents to rate the category questions");
                    } else if (invitees.length === 0) {
                        message.error("Uh oh! You should probably invite some people to take part");
                    } else {
                        const payload : ICreateProject = {
                            projectType: projectType,
                            projectName: projectName!,
                            directorAssessmentCategories: directorAssessmentCategories,
                            directorAssessmentCriteria: Object.fromEntries(directorAssessmentCriteria),
                            directorAssessmentDirectors: directorAssessmentDirectors,
                            directorAssessmentCriteriaRating: directorAssessmentQuestionRating,
                            invitees: invitees,
                        };

                        const createProjectResponse = await apiCreateProject({jwtToken: user.jwtToken, payload: payload});

                        if ('data' in createProjectResponse) {
                            if (createProjectResponse.data.id) {
                                complete = true;
                                message.success(`Project '${createProjectResponse.data.name}' created`);
                            }
                        } else if ('error' in createProjectResponse) {
                            // @ts-ignore
                            message.error(`Oops! Looks like there was a problem: ${createProjectResponse.error.data.message}`);
                        }
                    }
                }

                break;
        }
        setComplete(complete);
    }, [projectType, projectName, boardMeetingDetails, discussionTopics, boardMeetingReviewQuestions, boardMeetingReviewDirectorRating, boardMeetingReviewExecutiveQuestions, invitees, directorAssessmentCategories, apiCreateProject, user.jwtToken, directorAssessmentCriteria, orderedApiDirectorAssessmentCategories, directorAssessmentDirectors, directorAssessmentQuestionRating]);

    const onCancelProject = useCallback(() => {
        resetProject();
        carouselRef.current?.goTo(0);
        setSlideNumber(0);
    }, [carouselRef, resetProject]);

    const pages = useMemo(() => {
        const returnValue = [<TypeSelector key="type-selector" complete={complete} currentState={projectType} onChange={onProjectTypeChange}/>];

        switch (projectType) {
            case ProjectTypeEnum.BoardMeetingReview:
                returnValue.push(<ProjectName key="project-name" complete={complete} currentName={projectName} onSubmit={onProjectNameSet} />);
                returnValue.push(<BoardMeetingDetails key="board-meeting-details" complete={complete} onSubmit={onBoardMeetingDetailsSet} personnel={data ? data as unknown as IOrganisationPersonnel[] : []} currentState={boardMeetingDetails} />);
                returnValue.push(<DiscussionTopics key="discussion-topics" complete={complete} onSubmit={onDiscussionTopicsSet} personnel={data ? data as unknown as IOrganisationPersonnel[] : []} />);
                returnValue.push(<BoardMeetingReviewQuestions complete={complete} key="board-meeting-review-questions" onSubmit={onBoardMeetingReviewQuestionsSet} currentState={boardMeetingReviewQuestions} />);
                returnValue.push(<BoardMeetingReviewDirectorRating complete={complete} key="board-meeting-review-director-rating" currentState={boardMeetingReviewDirectorRating} onChange={onBoardMeetingReviewDirectorRatingSet}  />);
                returnValue.push(<BoardMeetingReviewExecutiveQuestions complete={complete} key="board-meeting-review-executive-questions" currentState={boardMeetingReviewExecutiveQuestions} onSubmit={onBoardMeetingReviewExecutiveQuestionsSet}  />);
                returnValue.push(<Invitees key="project-invitees" complete={complete} onSubmit={onInviteesSet} currentState={invitees} personnel={data ? data as unknown as IOrganisationPersonnel[] : []} boardMeetingDetails={boardMeetingDetails} />)
                if (complete) {
                    returnValue.push(<Complete key="project-complete" projectName={projectName} />);
                } else {
                    returnValue.push(<Ready key="project-ready" projectName={projectName} onBuild={onBuildProject} onCancel={onCancelProject} />)
                }
                break;
            case ProjectTypeEnum.DirectorAssessment:
                returnValue.push(<ProjectName key="project-name" complete={complete} currentName={projectName} onSubmit={onProjectNameSet} />);
                returnValue.push(<DirectorAssessmentCategories availableCategories={orderedApiDirectorAssessmentCategories!} complete={complete} key="director-assessment-categories" onSubmit={onDirectorAssessmentCategoriesSet} currentState={directorAssessmentCategories} />);
                directorAssessmentCategories.forEach((categoryHash) => {
                    const category = orderedApiDirectorAssessmentCategories?.find((category) => category.hash === categoryHash);
                    const subCategories = categorySubCategory?.filter((categorySubCategory) => categorySubCategory.directorAssessmentCategory.hash === categoryHash);
                    const subCategoryHashes = subCategories?.map((subCategory) => subCategory.hash);
                    const availableCriteria = categorySubCategoryCriteria?.filter((categoryCriteria) => subCategoryHashes?.includes(categoryCriteria.directorAssessmentSubCategory.hash));

                    if (category) {
                        returnValue.push(<DirectorAssessmentCategoryCriteria category={category}
                                                                             availableCriteria={availableCriteria!}
                                                                             complete={complete}
                                                                             key={`director-assessment-${categoryHash}-category`}
                                                                             onSubmit={onDirectorAssessmentCriteriaSet}
                                                                             currentState={directorAssessmentCriteria.get(categoryHash)}/>);
                    }
                });
                returnValue.push(<DirectorAssessmentDirectors personnel={data ? data as unknown as IOrganisationPersonnel[] : []}
                                                              complete={complete}
                                                              key="director-assessment-directors"
                                                              onSubmit={onDirectorAssessmentDirectorsSet}
                                                              currentState={directorAssessmentDirectors}/>);
                returnValue.push(<Invitees key="project-invitees" complete={complete} onSubmit={onInviteesSet} currentState={invitees} personnel={data ? data as unknown as IOrganisationPersonnel[] : []} />);

                if (complete) {
                    returnValue.push(<Complete key="project-complete" projectName={projectName} />);
                } else {
                    returnValue.push(<Ready key="project-ready" projectName={projectName} onBuild={onBuildProject} onCancel={onCancelProject} />)
                }

                break;
        }

        return returnValue;
    }, [complete, projectType, onProjectTypeChange, projectName, onProjectNameSet, onBoardMeetingDetailsSet, data, boardMeetingDetails, onDiscussionTopicsSet, onBoardMeetingReviewQuestionsSet, boardMeetingReviewQuestions, boardMeetingReviewDirectorRating, onBoardMeetingReviewDirectorRatingSet, boardMeetingReviewExecutiveQuestions, onBoardMeetingReviewExecutiveQuestionsSet, onInviteesSet, invitees, orderedApiDirectorAssessmentCategories, onDirectorAssessmentCategoriesSet, directorAssessmentCategories, onDirectorAssessmentDirectorsSet, directorAssessmentDirectors, onBuildProject, onCancelProject, categorySubCategory, categorySubCategoryCriteria, onDirectorAssessmentCriteriaSet, directorAssessmentCriteria]);

    useEffect(() => {
        if (moveForward) {
            carouselRef.current?.next();
            setMoveForward(false);
            setSlideNumber(slideNumber + 1);
        }
    }, [moveForward, carouselRef, slideNumber]);

    const onMoveForward = useCallback(() => {
        carouselRef.current?.next();
        setSlideNumber(slideNumber + 1);
    }, [carouselRef, slideNumber]);

    const onMoveBackward = useCallback(() => {
        carouselRef.current?.prev();
        setSlideNumber(slideNumber > 0 ? slideNumber - 1 : 0);
    }, [carouselRef, slideNumber]);

    return (
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>{user.organisation.name}</Breadcrumb.Item>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>New Project</Breadcrumb.Item>
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: '82vh',
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                }}
            >
                <h2>Add new project</h2>
                <Carousel
                    afterChange={onSlideChange}
                    ref={carouselRef}
                    infinite={false}
                    effect='scrollx'
                    dotPosition='bottom'
                    waitForAnimate={false}
                >
                    {pages.map((page) => (
                        page
                    ))}
                </Carousel>
                <div className='carousel-navigation'>
                    <Button className='button-prev' onClick={onMoveBackward}>
                        &lsaquo; Prev
                    </Button>
                    <Button className='button-next' onClick={onMoveForward}>
                        Next &rsaquo;
                    </Button>
                </div>
            </div>
        </Content>
    );
}

export default NewProjectPage;

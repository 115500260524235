import React, {useEffect, useMemo, useState} from "react";
import {IOrganisationPersonnel, IUpdateOrganisationPersonnel} from "../../services/direct-assess-api/types";
import {OrganisationPositionEnum} from "../../enums/OrganisationPositionEnum";
import {Button, Form, FormProps, Input, message, Select} from "antd";
import {Utils} from "../../Utils/Utils";
import {GeneralStatusEnum} from "../../enums/GeneralStatusEnum";
import {useUpdatePersonnelMutation} from "../../services/direct-assess-api";
import {useAuth} from "../../hooks/useAuth";

const { Option } = Select;
type LayoutType = Parameters<typeof Form>[0]['layout'];

interface IProps {
    personnel?: IOrganisationPersonnel;
    onClose: () => void;
}

type FieldType = {
    firstName: string;
    lastName: string;
    email: string;
    positions: string[];
    status: string;
};

const OrganisationPersonnelUpdate: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState<LayoutType>('vertical');
    const [apiUpdatePersonnel, apiUpdatePersonnelResult] = useUpdatePersonnelMutation();
    const { user, logout } = useAuth();

    const formItemLayout = formLayout === 'horizontal' ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;
    const buttonItemLayout = formLayout === 'horizontal' ? { wrapperCol: { span: 14, offset: 4 } } : null;

    const onFinish: FormProps<FieldType>['onFinish'] = async(values) => {
        const payload = {
            id: props.personnel?.id,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            positions: JSON.stringify(values.positions),
            status: values.status,
        } as IUpdateOrganisationPersonnel;

        const updateResponse = await apiUpdatePersonnel({jwtToken: user.jwtToken, payload: payload});

        if ('data' in updateResponse) {
            message.success(`'${payload.firstName} ${payload.lastName}' updated`);
            props.onClose();
        } else {
            message.error(`Uh oh! Looks like the update of '${payload.firstName} ${payload.lastName}' failed :(`)
        }
    };

    const positionKeys = useMemo(() => {
        return Object.keys(OrganisationPositionEnum);
    }, []);

    const statusKeys = useMemo(() => {
        return Object.values(GeneralStatusEnum);
    }, []);

    useEffect(() => {
        form.setFieldValue("firstName", props.personnel?.firstName);
        form.setFieldValue("lastName", props.personnel?.lastName);
        form.setFieldValue("email", props.personnel?.email);
        form.setFieldValue("status", props.personnel?.status);
        const positionsHeld = JSON.parse(props.personnel?.positions!);
        form.setFieldValue("positions", positionsHeld);
    }, [props.personnel]);

    return (
        <>
            <h2>Update {props.personnel?.firstName} {props.personnel?.lastName}'s details</h2>
            <Form
                {...formItemLayout}
                layout={formLayout}
                form={form}
                name="personnel-update-form"
                className="personnel-form"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item<FieldType> label="First name" name="firstName" rules={[{ required: true, message: 'Please enter first name'}]}>
                    <Input placeholder="First name" />
                </Form.Item>
                <Form.Item<FieldType> label="Last name" name="lastName" rules={[{ required: true, message: 'Please enter last name'}]}>
                    <Input placeholder="Last name" />
                </Form.Item>
                <Form.Item<FieldType> label="Email" name="email" rules={[{ required: true, type: 'email', message: 'Please enter a valid email address'}]}>
                    <Input placeholder="Email" />
                </Form.Item>
                <Form.Item<FieldType> label="Positions held" name="positions" rules={[{ required: true, message: 'At least one position should be added', type: 'array'}]}>
                    <Select mode="multiple" placeholder="Select the positions held">
                        {positionKeys.map((position, index) => (
                                <Option key={index} value={position}>{`${Utils.readableOrganisationPosition(position as OrganisationPositionEnum)}`}</Option>
                            )
                        )}
                    </Select>
                </Form.Item>
                <Form.Item<FieldType> label="Status" name="status" rules={[{ required: true, message: 'You have to specify a status', type: 'string'}]}>
                    <Select placeholder="Select status">
                        {statusKeys.map((status, index) => (
                                <Option key={index} value={status}>{`${Utils.readableGeneralStatus(status as GeneralStatusEnum)}`}</Option>
                            )
                        )}
                    </Select>
                </Form.Item>
                <Form.Item {...buttonItemLayout}>
                    <Button disabled={apiUpdatePersonnelResult.isLoading} type="primary" htmlType="submit">Submit</Button>
                    <Button disabled={apiUpdatePersonnelResult.isLoading} style={{marginLeft: '1rem'}} type="default" onClick={props.onClose} >Cancel</Button>
                </Form.Item>
            </Form>
        </>
    );
}

export default OrganisationPersonnelUpdate;

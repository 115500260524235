import React from "react";
import {IOrganisation} from "../../services/direct-assess-api/types";

interface IProps {
    organisation: IOrganisation;
}

const OrganisationDetails: React.FunctionComponent<IProps> = (props) => {

    return (
        <div className="project-tab">
            <h2>Details coming soon...</h2>
        </div>
    );
}

export default OrganisationDetails;


export enum OrganisationPositionEnum {
    ChiefExecutiveOfficer = "ChiefExecutiveOfficer",
    ChiefTechnologyOfficer = "ChiefTechnologyOfficer",
    ChiefOperatingOfficer = "ChiefOperatingOfficer",
    ChiefFinancialOfficer = "ChiefFinancialOfficer",
    ChiefMarketingOfficer = "ChiefMarketingOfficer",
    CompanySecretary = "CompanySecretary",
    ManagingDirector = "ManagingDirector",
    ExecutiveDirector = "ExecutiveDirector",
    NonExecutiveDirector = "NonExecutiveDirector",
    IndependentNonExecutiveDirector = "IndependentNonExecutiveDirector",
    ExecutiveChair = "ExecutiveChair",
    Chair = "Chair",
    MemberOfStaff = "MemberOfStaff",
    IndependentContractor = "IndependentContractor",
}

import React, {useCallback, useMemo, useState} from "react";
import {Col, List, Row} from "antd";
import {IFilledDirectorAssessmentCriteriaScoreDto} from "../../../services/direct-assess-api/types";

interface IProps {
    criteriaScores: IFilledDirectorAssessmentCriteriaScoreDto[];
}

const CriteriaScores: React.FunctionComponent<IProps> = (props) => {
    const [selectedCriteriaId, setSelectedCriteriaId] = useState<string>();

    const header = useMemo(() => {
        return (
            <Row>
                <Col span={21}>Criteria</Col>
                <Col span={1}>Score</Col>
            </Row>
        );
    }, []);
    
    const criteriaData  = useMemo(() => {
        return props.criteriaScores.map((item) => {
           return {
               id: item.criteriaHash,
               name: item.name,
               score: item.score,
           } 
        });
    }, [props.criteriaScores]);

    const onCriteriaSelected = useCallback((itemId: string) => {
        if (selectedCriteriaId && selectedCriteriaId === itemId) {
            setSelectedCriteriaId(undefined);
        } else {
            setSelectedCriteriaId(itemId);
        }
    }, [selectedCriteriaId]);

    return (
        <div className='criteria-scores'>
            <List
                header={header}
                bordered
                dataSource={criteriaData}
                renderItem={(item) => (
                    <List.Item
                        style={{backgroundColor: selectedCriteriaId === item.id ? 'lightgrey' : 'transparent'}}>
                        <Row style={{width: '100%', cursor: 'pointer'}} onClick={() => onCriteriaSelected(item.id)}>
                            <Col span={21}>{item.name}</Col>
                            <Col span={1}>{item.score}</Col>
                        </Row>
                    </List.Item>
                )}
            />
        </div>
    );
}

export default CriteriaScores;

import React from "react";
import {Breadcrumb, Layout, Tabs, theme} from "antd";
import {useAuth} from "../hooks/useAuth";
import DirectorAssessment from "../components/Admin/DirectorAssessment";
import BoardMeetingReview from "../components/Admin/BoardMeetingReview";

const { Content } = Layout;

const AdminPage: React.FunctionComponent = () => {
    const { user, logout } = useAuth();
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const boardMeetingReviewTab = <BoardMeetingReview />;
    const directorAssessmentTab = <DirectorAssessment />;

    const tabs = [ {
        label: 'Director Assessment',
        key: 'directorAssessment',
        children: directorAssessmentTab,
    }, {
        label: 'Board Meeting Review',
        key: 'boardMeetingReview',
        children: boardMeetingReviewTab,
    },
    ];

    return (
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>{user.organisation.name}</Breadcrumb.Item>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item>Admin</Breadcrumb.Item>
            </Breadcrumb>
            <div
                style={{
                    padding: 24,
                    minHeight: '82vh',
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                }}
            >
                <h2>Platform administration</h2>
                <Tabs
                    defaultActiveKey="directorAssessment"
                    type="card"
                    size="middle"
                    items={tabs}
                />
            </div>
        </Content>
    );
}

export default AdminPage;

import React, {useCallback, useState} from "react";
import {Card, Col, Row} from "antd";
import CategoryScores from "./CategoryScores";
import {IFilledDirectorAssessmentResponsesOverall} from "../../../services/direct-assess-api/types";
import {Utils} from "../../../Utils/Utils";

interface IProps {
    filledDirectorAssessmentResponses: IFilledDirectorAssessmentResponsesOverall;
}

const OverallScore: React.FunctionComponent<IProps> = (props) => {
    const [showCategories, setShowCategories] = useState<boolean>(false);

    const onCardClick = useCallback(() => {
        setShowCategories(!showCategories);
    }, [showCategories]);

    return (
      <div className='director-assessment-responses'>
          <Row gutter={16}>
              <Col span={9}>

              </Col>
              <Col span={6}>
                  <Card title='Overall Director Proficiency Score' bordered={true} onClick={onCardClick}>
                      <h1>{Utils.limitAndRound((props.filledDirectorAssessmentResponses.overallScore / 5) * 100, 0, 100, 1)}%</h1>
                      <p>Click to explore</p>
                  </Card>
              </Col>
              <Col span={9}>

              </Col>
          </Row>
          {showCategories && (
              <CategoryScores categoryScores={props.filledDirectorAssessmentResponses.categoryScores} />
          )}
      </div>
    );
}

export default OverallScore;

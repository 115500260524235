import { Suspense } from "react";
import {useOutlet} from "react-router-dom";

export const NoLayout = () => {
    const outlet = useOutlet();

    return (
        <Suspense fallback={null}>
            {outlet}
        </Suspense>
    )
};

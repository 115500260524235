import React, {useMemo} from "react";
import {IFilledResponses} from "../../../services/direct-assess-api/types";
import {Table} from "antd";
import {Utils} from "../../../Utils/Utils";
import {IDirector} from "../../../types/types";

const { Column, ColumnGroup } = Table;

interface IProps {
    filledResponses: IFilledResponses | undefined;
}

const DirectorAssessmentProjectCriteriaPerformance: React.FunctionComponent<IProps> = (props) => {

    const data = useMemo(() => {
        const returnValue = [];

        if (props.filledResponses?.filledDirectorAssessmentResponses.directorCriteriaPerformance.categories) {
            for (const category of props.filledResponses?.filledDirectorAssessmentResponses.directorCriteriaPerformance.categories) {
                let entry: any = {
                    key: category.categoryHash,
                    name: category.categoryName,
                    children: [],
                };

                for (const subCategory of category.subcategories) {
                    let subCategoryEntry: any = {
                        key: subCategory.subcategoryHash,
                        name: subCategory.subcategoryName,
                        children: [],
                    }

                    for (const criteria of subCategory.criteria) {
                        let criteriaEntry: any = {
                            key: criteria.criteriaHash,
                            name: criteria.criteriaName,
                            meanRaw: criteria.meanRaw,
                            meanScaled: criteria.meanScaled,
                        }

                        for (const director of criteria.directors) {
                            const rawString = Utils.limitAndRound(director.raw, 0, 5, 1);
                            const scaledString = Utils.limitAndRound(director.scaled, 0, 5, 1);
                            criteriaEntry[`${director.directorHash}_raw`] = rawString;
                            criteriaEntry[`${director.directorHash}_scaled`] = scaledString;
                        }

                        subCategoryEntry.children.push(criteriaEntry);
                    }

                    entry.children.push(subCategoryEntry);
                }
                returnValue.push(entry);
            }
        }

        return returnValue;
    }, [props.filledResponses?.filledDirectorAssessmentResponses.directorCriteriaPerformance]);

    const directors = useMemo(() => {
        const returnValue: IDirector[] = [];

        if (props.filledResponses?.filledDirectorAssessmentResponses.directorCriteriaPerformance.categories[0].subcategories[0]) {
            for (const director of props.filledResponses.filledDirectorAssessmentResponses.directorCriteriaPerformance.categories[0].subcategories[0].criteria[0].directors) {
                returnValue.push({hash: director.directorHash, name: director.directorName});
            }
        }

        return returnValue;
    }, [props.filledResponses?.filledDirectorAssessmentResponses.directorCriteriaPerformance.categories]);

    const colWidth = 40;

    return (
        <Table dataSource={data} scroll={{ x: 2000 }}>
            <Column title="Category / Subcategory / Criterion" dataIndex="name" key="name" width={180} fixed={true} />
            <ColumnGroup title='Mean' key='mean' fixed={true}>
                <Column title="Raw" key='mean_raw' width={colWidth} fixed={true} align='center' render={(_: any, record: any) => {
                    return (
                        <div>
                            {Utils.limitAndRound(record.meanRaw, 0, 5, 1)}
                        </div>
                    );
                }}/>
                <Column title="Scaled" key='mean_scaled' width={colWidth} fixed={true} align='center' render={(_: any, record: any) => {
                    return (
                        <div>
                            {Utils.limitAndRound(record.meanScaled, 0, 5, 1)}
                        </div>
                    );
                }}/>
            </ColumnGroup>
            {directors.map((director) => {
                return (
                    <ColumnGroup title={director.name} key={director.hash} className='director-performance-title'>
                        <Column title="Raw" key={`${director.hash}_raw`} width={colWidth} align='center'  render={(_: any, record: any) => {
                            return (
                                <div>
                                    {record[`${director.hash}_raw`] ? record[`${director.hash}_raw`] : ''}
                                </div>
                            );
                        }}/>
                        <Column title="Scaled" key={`${director.hash}_scaled`} width={colWidth} align='center' render={(_: any, record: any) => {
                            return (
                                <div>
                                    {record[`${director.hash}_scaled`] ? record[`${director.hash}_scaled`] : ''}
                                </div>
                            );
                        }}/>

                    </ColumnGroup>
                );
            })}

        </Table>
    );
}

export default DirectorAssessmentProjectCriteriaPerformance;

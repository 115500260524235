import React, {useMemo} from "react";
import {IFilledResponses} from "../../services/direct-assess-api/types";
import {ProjectTypeEnum} from "../../enums/ProjectTypeEnum";
import BoardMeetingReviewProjectResponses from "./BoardMeetingReviewProjectResponses";
import DirectorAssessmentResults from "./DirectorAssessmentResults/DirectorAssessmentResults";

interface IProps {
    projectType: ProjectTypeEnum;
    filledResponses: IFilledResponses | undefined;
}
const ProjectResponses: React.FunctionComponent<IProps> = (props) => {
    
    const componentContent = useMemo(() => {
        if (props.projectType === ProjectTypeEnum.BoardMeetingReview) {
            return (
              <BoardMeetingReviewProjectResponses filledResponses={props.filledResponses} />
            );
        } else {
            return (
                <DirectorAssessmentResults filledResponses={props.filledResponses} />
            );
        }
    }, [props]);

    return (
        <div className="project-tab">
            {componentContent}
        </div>
    );
}

export default ProjectResponses;

import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {
    useGetProjectSurveyQuery,
    useSendProjectSurveyResultMutation
} from "../services/direct-assess-api";
import {IProjectSurvey} from "../services/direct-assess-api/types";
import 'survey-core/defaultV2.min.css';
import {Model, SvgRegistry, FunctionFactory, ElementFactory, IPage, IElement} from 'survey-core';
import {ReactElementFactory, ReactQuestionFactory, Survey} from 'survey-react-ui';
import { Converter } from 'showdown';
import { iconDescriptionShowSvg, iconDescriptionHideSvg } from "./SurveyIcons";
import * as SurveyTheme from "survey-core/themes";
import {
    RECORD_VIDEO_CUSTOM_TYPE,
    QuestionRecordVideoModel,
    SurveyQuestionRecordVideo
} from "../components/SurveyJsCustom/RecordVideoQuestion";
import {CUSTOM_PROGRESS_BAR_TYPE, CustomProgressBar} from "../components/SurveyJsCustom/CustomProgressBar";

const STORAGE_ITEM_KEY = "chairtools-survey";

SvgRegistry.registerIconFromSvg("description-show", iconDescriptionShowSvg);
SvgRegistry.registerIconFromSvg("description-hide", iconDescriptionHideSvg);
const SurveyJsProjectPage: React.FunctionComponent = () => {
    const { projectHash, inviteeHash } = useParams();
    const {data, error} = useGetProjectSurveyQuery({projectHash: projectHash!, inviteeHash: inviteeHash!});
    const [apiSendProjectSurveyResult, apiSendProjectSurveyResultResult] = useSendProjectSurveyResultMutation();
    const [startAtPageNo, setStartAtPageNo] = useState();

    const surveyJson = useMemo(() => {
        const projectSurvey = data ? data as unknown as IProjectSurvey : undefined;

        if (projectSurvey) {
            return JSON.parse(projectSurvey.surveyJson);
        }

        return undefined;
    }, [data]);

    const onSurveyComplete = useCallback(async (survey: any) => {
        const surveyResultResponse = await apiSendProjectSurveyResult({projectHash: projectHash!, inviteeHash: inviteeHash!, payload: survey.data});

        if ('data' in surveyResultResponse) {
            window.localStorage.setItem(STORAGE_ITEM_KEY, "");
        }
        
    }, [apiSendProjectSurveyResult, inviteeHash, projectHash]);

    const saveSurveyData = useCallback((survey: Model) => {
        const data = survey.data;
        data.pageNo = survey.currentPageNo;
        window.localStorage.setItem(STORAGE_ITEM_KEY, JSON.stringify(data));
    }, []);

    const restoreSurveyData = useCallback((survey: Model) => {
        const prevData = window.localStorage.getItem(STORAGE_ITEM_KEY) || null;
        if (prevData) {
            const data = JSON.parse(prevData);
            survey.data = data;
            if (data.pageNo) {
                survey.currentPageNo = data.pageNo;
                setStartAtPageNo(data.pageNo);
            }
        }
    }, []);

    const survey = useMemo(() => {
        if (surveyJson) {
            const survey = new Model(surveyJson);

            restoreSurveyData(survey);

            survey.applyTheme(SurveyTheme.FlatLight);
            survey.setValue("inviteeHash", inviteeHash);
            survey.onComplete.add(onSurveyComplete);

            const converter = new Converter();
            survey.onTextMarkdown.add(function (survey, options) {
                // Convert Markdown to HTML
                let str = converter.makeHtml(options.text);
                // Remove root paragraphs <p></p>
                str = str.substring(3);
                str = str.substring(0, str.length - 4);
                // Set HTML markup to render
                options.html = str;
            });

            /*
            survey.onGetQuestionTitleActions.add((_, options) => {
                options.titleActions = [{
                    id: "show-description",
                    iconName: "icon-description-show",
                    action: (action) => {
                        const q = options.question;
                        q.descriptionLocation = q.descriptionLocation !== "hidden" ? "hidden" : "underTitle";
                        action.iconName = q.descriptionLocation !== "hidden" ? "icon-description-hide" : "icon-description-show";
                    }
                }];
            });*/

            survey.onValueChanged.add(saveSurveyData);
            survey.onCurrentPageChanged.add(saveSurveyData);

            return survey
        }

        return undefined;
    }, [inviteeHash, onSurveyComplete, surveyJson, saveSurveyData, restoreSurveyData]);

    const onGotoPage = useCallback((pageNo: number) => {
        if (survey) {
            if (pageNo <= survey.currentPageNo || survey.currentPage.validate()) {
                setPageNo(pageNo);
            }
        }
    }, [survey]);

    useEffect(() => {
        if (startAtPageNo) {
            onGotoPage(startAtPageNo);
            setStartAtPageNo(undefined);
        }
    }, [startAtPageNo, onGotoPage]);

    const [pageNo, setPageNo] = useState(survey ? survey.currentPageNo : 0);

    const newSubcategoryCriteriaOrder = useMemo(() => {
        const returnValue = new Map<string, Set<string>>();

        if (survey) {
            const surveyPages: IPage[] = survey.getSurvey().pages;

            for (const surveyPage of surveyPages) {
                //console.log(`surveyPage: ${JSON.stringify(surveyPage)}`);

                const surveyPageElements: IElement[] = surveyPage.elements;

                if (surveyPage.name.endsWith('_sub_category')) {
                    let subcategoryCriteriaOrderSet: Set<string> | undefined = new Set();

                    if (returnValue.has(surveyPage.name)) {
                        subcategoryCriteriaOrderSet = returnValue.get(surveyPage.name);
                    }

                    // Sub category page
                    for (const surveyPageElement of surveyPageElements) {
                        if (surveyPageElement.getType() === 'panel') {
                            const panelName = surveyPageElement.name;

                            if (panelName.endsWith('_criteria')) {
                                if (subcategoryCriteriaOrderSet) {
                                    subcategoryCriteriaOrderSet.add(surveyPageElement.name);
                                }
                            }
                        }
                    }

                    returnValue.set(surveyPage.name, subcategoryCriteriaOrderSet!);
                }
            }
        }

        return returnValue;
    }, [survey]);

    const onSelfRatingChange = useCallback((name: string, value: string) => {
        const criteriaName = name.substring(0, name.indexOf('_self_rating'));
        const subcategoryName = name.substring(0, name.indexOf('_sub_category_') + '_sub_category'.length);

        const subcategoryCriteriaOrderSet = newSubcategoryCriteriaOrder.get(subcategoryName);

        let nextCriteria;

        if (subcategoryCriteriaOrderSet) {
            const numberOfCriteria = subcategoryCriteriaOrderSet?.size;

            const subcategoryCriteriaOrderArray = Array.from(subcategoryCriteriaOrderSet);
            for (let i = 0; i < subcategoryCriteriaOrderArray.length; i++) {
                if (subcategoryCriteriaOrderArray[i] === criteriaName) {
                    if (i < numberOfCriteria - 1) {
                        nextCriteria = subcategoryCriteriaOrderArray[i + 1];
                        break;
                    }
                }
            }
        }

        if (nextCriteria) {
            const surveyPages: IPage[] = survey!.getSurvey().pages;

            for (const surveyPage of surveyPages) {
                const surveyPageElements: IElement[] = surveyPage.elements;

                if (surveyPage.name.endsWith('_sub_category')) {
                    // Sub category page
                    for (const surveyPageElement of surveyPageElements) {
                        if (surveyPageElement.getType() === 'panel') {
                            const panelName = surveyPageElement.name;

                            if (panelName === nextCriteria) {
                                surveyPageElement.expand();
                                break;
                            }
                        }
                    }
                }
            }
        }
    }, [survey, newSubcategoryCriteriaOrder]);

    useEffect(() => {
        if (survey) {
            const surveyPages: IPage[] = survey.getSurvey().pages;

            for (const surveyPage of surveyPages) {
                //console.log(`surveyPage: ${JSON.stringify(surveyPage)}`);

                const surveyPageElements: IElement[] = surveyPage.elements;

                if (surveyPage.name.endsWith('_sub_category')) {
                    // Sub category page
                    for (const surveyPageElement of surveyPageElements) {

                        if (surveyPageElement.getType() === 'panel') {
                            const panelElements = surveyPageElement.getPanel().elements;

                            for (const panelElement of panelElements) {
                                if (panelElement.getType() === 'dropdown' && panelElement.name.endsWith('_criteria_self_rating')) {
                                    panelElement.registerFunctionOnPropertyValueChanged('value', (value: string) => onSelfRatingChange(panelElement.name, value), panelElement.name);
                                }
                            }
                        }
                    }
                }
            }
        }
    }, [survey, onSelfRatingChange]);

    function getDropdownText(params: any[]) {
        // @ts-ignore
        let dropdown = this.survey.getQuestionByName(params[0]);
        let selectedItem = dropdown.choices.find((x: { selected: any; })=>x.selected);
        if (!!selectedItem)
            return selectedItem.text;
    }

    useEffect(() => {
        if (survey) {
            survey.addLayoutElement({
                id: "custom-progressbar",
                component: CUSTOM_PROGRESS_BAR_TYPE,
                container: "header",
                data: survey
            });
        }
    }, [survey]);

    FunctionFactory.Instance.register("getDropdownText", getDropdownText);

    ElementFactory.Instance.registerElement(RECORD_VIDEO_CUSTOM_TYPE, (name) => {
        return new QuestionRecordVideoModel(name);
    });



    ReactElementFactory.Instance.registerElement(CUSTOM_PROGRESS_BAR_TYPE, props => {
        return React.createElement(CustomProgressBar, {...props, onGotoPage});
    });

    // Register `SurveyQuestionRecordVideo` as a class that renders `record-video-question` questions
    ReactQuestionFactory.Instance.registerQuestion(RECORD_VIDEO_CUSTOM_TYPE, (props) => {
        return React.createElement(SurveyQuestionRecordVideo, props);
    });

    return (
        <>
            {survey && (
                <Survey
                    currentPageNo={pageNo}
                    model={survey}
                />
            )}
        </>
    );
}

export default SurveyJsProjectPage;
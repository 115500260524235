import React, {useState} from "react";
import {
    ICreateSurveySubCategoryDto, ISurveyCategory,
} from "../../services/direct-assess-api/types";
import {Button, Form, FormProps, Input, message} from "antd";
import {
    useCreateAdminDirectorAssessmentSurveySubCategoryMutation,
} from "../../services/direct-assess-api";
import {useAuth} from "../../hooks/useAuth";

type LayoutType = Parameters<typeof Form>[0]['layout'];

const { TextArea } = Input;

interface IProps {
    onClose: () => void;
    position: number;
    organisation: string;
    category: ISurveyCategory;
}

type FieldType = {
    name: string;
    description: string;
};

const DirectorAssessmentSubCategoryCreate: React.FunctionComponent<IProps> = (props) => {
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState<LayoutType>('vertical');
    const [apiCreateDirectorAssessmentSubCategory, apiCreateDirectorAssessmentSubCategoryResult] = useCreateAdminDirectorAssessmentSurveySubCategoryMutation();
    const { user, logout } = useAuth();

    const formItemLayout = formLayout === 'horizontal' ? { labelCol: { span: 4 }, wrapperCol: { span: 14 } } : null;
    const buttonItemLayout = formLayout === 'horizontal' ? { wrapperCol: { span: 14, offset: 4 } } : null;

    const onFinish: FormProps<FieldType>['onFinish'] = async(values) => {
        const payload = {
            name: values.name,
            description: values.description,
            position: props.position,
            organisationId: props.organisation === 'foundation' ? undefined : +props.organisation,
            categoryId: props.category.id,
        } as ICreateSurveySubCategoryDto;

        const createResponse = await apiCreateDirectorAssessmentSubCategory({jwtToken: user.jwtToken, payload: payload});

        if ('data' in createResponse) {
            message.success(`Sub category '${payload.name}' added`);
            props.onClose();
        } else {
            message.error(`Uh oh! Looks like adding sub category '${payload.name}' failed :(`)
        }
    };

    return (
        <>
            <h2>Add sub category</h2>
            <Form
                {...formItemLayout}
                layout={formLayout}
                form={form}
                name="director-assessment-sub-category-create-form"
                className="add-category-form"
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item<FieldType> label="Name" name="name" rules={[{ required: true, message: 'Please enter name'}]}>
                    <Input placeholder="Category name" />
                </Form.Item>
                <Form.Item<FieldType> label="Description" name="description" rules={[{ required: false }]}>
                    <TextArea placeholder="Description (optional)" rows={4} />
                </Form.Item>
                <Form.Item {...buttonItemLayout}>
                    <Button disabled={apiCreateDirectorAssessmentSubCategoryResult.isLoading} type="primary" htmlType="submit">Submit</Button>
                    <Button disabled={apiCreateDirectorAssessmentSubCategoryResult.isLoading} style={{marginLeft: '1rem'}} type="default" onClick={props.onClose} >Cancel</Button>
                </Form.Item>
            </Form>
        </>
    );
}

export default DirectorAssessmentSubCategoryCreate;

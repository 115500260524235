import {ReactSurveyElement} from "survey-react-ui";

export const CUSTOM_PROGRESS_BAR_TYPE = "custom-progress-bar";

export class CustomProgressBar extends ReactSurveyElement {

    render() {
        if (this.props.model.progressValue === 0) {
            return null;
        }

        let passed = true;

        return (
            <div className="custom-progress-bar">
                <div className="sd-progress-buttons__container">
                    <div className="sd-progress-buttons__list-container">
                        <ul className="sd-progress-buttons__list">
                            {this.props.model.pages.map((page: any, index: number) => {
                                if (index !== 0) {
                                    let current = false;

                                    if (page.name == this.props.model.currentPage) {
                                        current = true;
                                        passed = false;
                                    }

                                    const className = passed === true ? 'sd-progress-buttons__list-element--passed' : '' + (current === true ? 'sd-progress-buttons__list-element--current' : '');

                                    return (
                                        <li className={className} data-page-number="" key={index}>
                                            <div className="sd-progress-buttons__connector"></div>
                                            <div className="sd-progress-buttons__page-title"
                                                 title="Budgeting and Reporting"><span
                                                className="sv-string-viewer">{page.name.indexOf('_') === -1 ? page.name : ''}</span>
                                            </div>
                                            <div className="sd-progress-buttons__page-description" title=""></div>
                                            <div className="sd-progress-buttons__button" onClick={() => this.props.onGotoPage(index)}>
                                                <div className="sd-progress-buttons__button-background"></div>
                                                <div className="sd-progress-buttons__button-content"></div>
                                                <span></span></div>
                                        </li>
                                    );
                                }

                                return null;
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
